import { useEffect, useState } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import { useScreenQuery, useSnackbar } from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import type { GetPlaceDetailsInTripsQuery } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import {
  onGuideSessionStorageHoverIdChange,
  useGuideSessionStorageIds,
} from 'src/pages/guides/details'
import type { GuideOwner } from 'src/pages/guides/details/types'
import { onGuideSessionStorageSelectedIdChange } from 'src/pages/guides/details/useGuideSessionStorageIds'
import { useGetTripPlaceDetailsLazyQuery } from 'src/pages/trips/hooks'
import {
  encodeGuideId,
  getGeocoderLatitude,
  getGeocoderLongitude,
  getGuidePlaceLatLng,
} from 'src/utils'
import { GuideDraftMapInfoWindow } from './GuideDraftMapInfoWindow'
import { GuideDraftMapMarker } from './GuideDraftMapMarker'
import { GuideDraftMapPlaceMarker } from './GuideDraftMapPlaceMarker'
import { getGuideDraftCreatePublishedVariables } from '../../guideDraftUtils'
import type { GuideDraftPublishedEvent } from '../../types'
import { useCreateGuideDraftEventMutation } from '../../useCreateGuideDraftEventMutation'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'

interface GuideDraftMapMarkersProps {
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  owner: GuideOwner
  placePoints: google.maps.places.PlaceResult[]
  points: GuideDraftPublishedEvent[]
}

export const GuideDraftMapMarkers = ({
  onMapMarkerCenterChange,
  owner,
  placePoints,
  points,
}: GuideDraftMapMarkersProps) => {
  const isGuideUrlShortEnabled = useFlag('guideUrlShort')
  const map = useGoogleMap()
  const { hoverId, selectedId } = useGuideSessionStorageIds()
  const [createPublishedEvent] = useCreateGuideDraftEventMutation()
  const [getPlaceDetails, { data: placeDetailsData, loading: isLoading }] =
    useGetTripPlaceDetailsLazyQuery()
  const { guideDraftId } = useParams()
  const { isMobileOrTablet } = useScreenQuery()
  const { addSuccessSnack, addMailSnack, addErrorSnack } = useSnackbar()
  const [infoWindowPosition, setInfoWindowPosition] =
    useState<google.maps.LatLng>()
  const [showInfoWindow, setShowInfoWindow] = useState(false)

  useEffect(() => {
    const onMapClickListener = map.addListener('click', onMapClick)

    return () => {
      onMapClickListener.remove()
    }
  }, [])

  const onAddEvent = async (placeDetailsData: GetPlaceDetailsInTripsQuery) => {
    try {
      addMailSnack({ title: 'Adding to guide...' })
      await createPublishedEvent({
        variables: getGuideDraftCreatePublishedVariables({
          guideDraftId: encodeGuideId({
            guideId: guideDraftId,
            isGuideDraft: true,
            isGuideUrlShortEnabled,
          }),
          placeDetailsData,
        }),
      })
      addSuccessSnack({
        timeout: 1000,
        title: 'Added to guide',
      })
      onGuideSessionStorageHoverIdChange('')
      onGuideSessionStorageSelectedIdChange('')
      setShowInfoWindow(false)
    } catch {
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  const onMapClick = async (event: google.maps.MapMouseEvent) => {
    if ('placeId' in event && event.placeId) {
      event.stop()
      setInfoWindowPosition(event.latLng)
      setShowInfoWindow(true)
      try {
        await getPlaceDetails({
          variables: {
            includeImageLinks: true,
            includeWebsite: true,
            placeDetailsRequest: {
              placeId: event.placeId.toString(),
            },
          },
        })
      } catch (error) {
        console.error(error)
      }
    } else {
      setShowInfoWindow(false)
    }
  }

  const onMarkerClick = (point: GuideDraftPublishedEvent) => {
    const { addresses, id } = point ?? {}
    setShowInfoWindow(false)

    onMapMarkerCenterChange({
      lat: getGeocoderLatitude(addresses?.[0]?.lat),
      lng: getGeocoderLongitude(addresses?.[0]?.long),
    })

    if (!isMobileOrTablet) {
      onGuideSessionStorageSelectedIdChange(id)
    } else {
      onGuideSessionStorageHoverIdChange(id)
    }
  }

  const onMarkerPopupClick = (id: GuideDraftPublishedEvent['id']) => {
    if (isMobileOrTablet) onGuideSessionStorageSelectedIdChange(id)
  }

  const onPlaceMarkerClick = (placeResult: google.maps.places.PlaceResult) => {
    const { geometry, place_id } = placeResult ?? {}
    setShowInfoWindow(false)

    if (isMobileOrTablet) {
      if (geometry?.location)
        onMapMarkerCenterChange(getGuidePlaceLatLng(geometry?.location))

      onGuideSessionStorageHoverIdChange(place_id)
    }
  }

  return (
    <>
      {placePoints?.map(placePoint => (
        <GuideDraftMapPlaceMarker
          key={placePoint?.place_id}
          isHovered={placePoint?.place_id === hoverId}
          isSelected={placePoint?.place_id === selectedId}
          point={placePoint}
          onAddEvent={async () => {
            const response = await getPlaceDetails({
              variables: {
                placeDetailsRequest: {
                  placeId: placePoint?.place_id,
                },
              },
            })
            onAddEvent(response?.data)
          }}
          onClick={onPlaceMarkerClick}
        />
      ))}
      {points?.map(point => (
        <GuideDraftMapMarker
          key={point?.id}
          isHovered={point?.id === hoverId}
          isSelected={point?.id === selectedId}
          owner={owner}
          point={point}
          onClick={onMarkerClick}
          onMouseEnter={() => onGuideSessionStorageHoverIdChange(point?.id)}
          onMouseLeave={() => onGuideSessionStorageHoverIdChange('')}
          onPopupClick={onMarkerPopupClick}
        />
      ))}
      {showInfoWindow && (
        <GuideDraftMapInfoWindow
          infoWindowPosition={infoWindowPosition}
          isLoading={isLoading}
          placeDetailsData={placeDetailsData}
          onAddEvent={onAddEvent}
          onDismiss={() => setShowInfoWindow(false)}
        />
      )}
    </>
  )
}
