import { useState } from 'react'
import type { ButtonSize, ButtonVariant } from '@travelpass/design-system'
import { Button, useSnackbar } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { SignInModal } from 'src/pages/signin'
import { getGuideMetricString } from './'
import type { GuideData } from '../../details/types'
import {
  getCurrentUserLikedGuidesQuery,
  useGetUserLikesGuideQuery,
} from '../../useGetUserLikesGuideQuery'
import { useLikeGuideMutation } from '../../useLikeGuideMutation'

interface GuideMetricLikeProps {
  guideData: Pick<GuideData, 'id' | 'numberOfLikes'>
  hideLabel?: boolean
  isDisabled?: boolean
  size?: ButtonSize
  variant?: ButtonVariant
}

export const GuideMetricLike = ({
  guideData,
  hideLabel = false,
  isDisabled = false,
  size = 'medium',
  variant = 'text',
}: GuideMetricLikeProps) => {
  const { isAnonymous } = useFirebaseUser()
  const { id, numberOfLikes } = guideData ?? {}
  const { hasLikedGuide } = useGetUserLikesGuideQuery(id)
  const [likeGuide] = useLikeGuideMutation()
  const { addErrorSnack } = useSnackbar()
  const [showSignModal, setShowSignModal] = useState(false)

  const onLikeGuide = async () => {
    if (isAnonymous) {
      setShowSignModal(true)
    } else {
      try {
        likeGuide({
          variables: {
            guideId: id,
          },
          refetchQueries: [getCurrentUserLikedGuidesQuery],
        })
      } catch (error) {
        addErrorSnack({ timeout: 1000, title: 'Already liked!' })
      }
    }
  }

  return (
    <>
      <Button
        aria-label='Like this guide'
        aria-pressed={hasLikedGuide}
        isDisabled={isDisabled}
        size={size}
        startIcon={isDisabled || !hasLikedGuide ? 'favoriteBlank' : 'favorite'}
        variant={variant}
        onClick={debounce(onLikeGuide, 100)}
      >
        {getGuideMetricString(numberOfLikes, hideLabel ? '' : 'like')}
      </Button>
      {showSignModal && <SignInModal onClose={() => setShowSignModal(false)} />}
    </>
  )
}
