import {
  type GetCurrentUserQueryInDashboardQuery,
  ProfileImageSource,
} from 'src/__generated__/graphql'

export const dashboardMock: GetCurrentUserQueryInDashboardQuery = {
  currentUser: {
    id: 'VXNlcjp1c3JfMDFHRVEySzRBUlRDUFlGRUZFN0hQWFdRNkc=',
    userProfile: {
      id: 'VXNlclByb2ZpbGU6dXNwcm9mXzAxSFQzNzVLMkhFUFA1UERRWEJKOFEzRkVW',
      accountHandle: 'jdoe',
      displayName: 'John Doe',
      followCount: {
        countOfFollowed: 1,
        countOfFollowing: 2,
      },
      guideCount: 2,
      isUserOwner: true,
      profileCompletion: {
        completionPercentage: 0.4,
        hasBio: true,
        hasProfileImage: true,
        hasSocialLinks: false,
        hasTravelStats: false,
        hasTravelStyle: false,
        __typename: 'ProfileCompletion',
      },
      profileImageSource: ProfileImageSource.ClaimsImage,
      profileImageUrl:
        'https://lh3.googleusercontent.com/a/ALm5wu2OT9aLdGrKKiNn4IYSsEpQjaHB7dAKZBpOpuLD=s192-c',
      userId: 'VXNlcjp1c3JfMDFHRVEySzRBUlRDUFlGRUZFN0hQWFdRNkc=',
      __typename: 'UserProfile',
    },
    __typename: 'User',
  },
}
