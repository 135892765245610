import { Skeleton } from '@travelpass/design-system'
import { DashboardFeedCardLoadingOld } from './DashboardFeedCardLoadingOld'

export const DashboardFeedLoadingOld = () => (
  <div className='space-y-6'>
    <div className='h-5.25 w-24 md:h-6'>
      <Skeleton />
    </div>
    <div className='space-y-6'>
      <DashboardFeedCardLoadingOld />
      <DashboardFeedCardLoadingOld />
      <DashboardFeedCardLoadingOld />
    </div>
  </div>
)
