import { useState } from 'react'
import {
  Button,
  Icon,
  useScreenQuery,
  useSnackbar,
} from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import { ParticipantStatus } from 'src/__generated__/graphql'
import { ButtonLink } from 'src/common/components'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { VoteModalListener } from 'src/pages/competition/VoteModalListener'
import { dispatchToggleVoteModal } from 'src/pages/competition/dispatchToggleVoteModal'
import { useCreateVoteMutation } from 'src/pages/competition/hooks/useCreateVoteMutation'
import { userCompetitionReadyState } from 'src/pages/competition/utils/userCompetitionReadyState'
import { SignInModal } from 'src/pages/signin'
import { getTracker } from 'src/utils'
import { useGetUserCompetitionInfo } from './useGetUserCompetitionInfo'

export const CompetitionVoteCta = () => {
  const { isDesktopScreen } = useScreenQuery()
  const { accountHandle } = useParams()
  const { data } = useGetUserCompetitionInfo({
    accountHandle,
  })
  const [createVote] = useCreateVoteMutation()
  const { isAnonymous } = useFirebaseUser()
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false)
  const { addErrorSnack, addSuccessSnack } = useSnackbar()

  const profile = data?.viewUserProfile
  const { competitionInfo, displayName, isUserOwner } = profile ?? {}
  /**@todo use to conditionally disable the Vote button when ready on BE */
  // const { canVoteLeaderboard } = competitionInfo ?? {}
  const { status } = competitionInfo ?? {}
  const readyState = userCompetitionReadyState(status, isUserOwner)
  const isReady = readyState === ParticipantStatus.Ready
  const showCta =
    readyState === (ParticipantStatus.Ready || ParticipantStatus.Entered)

  const onClose = () => {
    setIsSignInModalOpen(false)
    !isAnonymous &&
      addSuccessSnack({
        title: 'Successfully signed in!',
        subTitle: 'You may now vote for contestants',
      })
  }

  const onVoteClick = async userProfile => {
    if (isAnonymous) return setIsSignInModalOpen(true)
    else {
      try {
        const response = await createVote({
          variables: {
            input: {
              tracker: getTracker(''),
              userProfileId: userProfile.id,
            },
          },
        })
        if (response?.data) {
          dispatchToggleVoteModal(userProfile)
        }
      } catch (e) {
        addErrorSnack({ title: 'Error', subTitle: e.message })
      }
    }
  }

  if (!showCta) return null

  return (
    <>
      <div className='bg-warm-grey job-promotion max-h-190px b-1px b-solid b-beachDark bg-bottom-right flex flex-col gap-5 rounded-lg bg-[url(https://static.travelpass.com/assets/job-campaign-2024/profile-cta-squiggle-bottom.webp)] bg-no-repeat md:flex-row lg:ml-0 lg:items-center'>
        <section className='of-y-hidden space-y-3% flex flex-col items-center justify-center bg-opacity-50 bg-[url(https://static.travelpass.com/assets/job-campaign-2024/profile-cta-squiggle-top.webp)] bg-no-repeat pb-7 pt-12 md:items-start md:pl-12 lg:max-h-full [&_button]:bg-transparent'>
          <h3 className='type-h4 c-forestDark'>
            {isReady
              ? `Help ${displayName} win a dream vacation!`
              : "You're almost done!"}
          </h3>
          <p className='type-body-1-mobile c-grey-800'>
            {isReady
              ? 'Vote to help this traveler win a $20,000 dream vacation!'
              : 'Finish up the last few steps to enter for a chance to win.'}
          </p>
          <div className='flex flex-col items-center gap-4 md:flex-row md:gap-6'>
            {isReady ? (
              <Button
                // isDisabled={!canVoteLeaderboard}
                label='Vote'
                size={isDesktopScreen ? 'large' : 'small'}
                startIcon='thumbUpOffAlt'
                variant='outlined'
                onClick={() => {
                  onVoteClick({ accountHandle, ...profile })
                }}
              />
            ) : (
              <ButtonLink
                className='c-forestLight b-forestLight text-forestLight hover:text-forest hover:b-forest hover:c-forest capitalize hover:bg-transparent'
                to='/dashboard'
                variant='outline'
              >
                See Next Steps
              </ButtonLink>
            )}
            {isReady && (
              <a
                className='decoration-none type-button c-newForest inline-flex items-center'
                href='/competition/about'
                target='_blank'
              >
                Learn More
                <Icon name='arrowForward' />
              </a>
            )}
          </div>
        </section>
        <section className='hidden md:block'>
          <img
            alt='woman with hot air balloons and polaroids'
            className=''
            src='https://static.travelpass.com/assets/job-campaign-2024/profile-cta-hero.webp'
          />
        </section>
      </div>
      <VoteModalListener />
      {isSignInModalOpen && (
        <SignInModal initiallyShowCreateAccount={true} onClose={onClose} />
      )}
    </>
  )
}
