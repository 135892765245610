import { EventType } from 'src/__generated__/graphql'
import type { GuideEventsOldData } from '../../types'

export const guideEventsOldMock: GuideEventsOldData = {
  id: 'R3VpZGU6Z2RlXzAxSFZIMjVCNU1WWFJFWDNYS0UzSlE2MFFY',
  groupedEvents: [
    {
      id: null,
      description: null,
      events: [],
      name: 'Uncategorized',
      __typename: 'GroupedEventCategory',
    },
    {
      id: 'UHVibGlzaGVkRXZlbnRDYXRlZ29yeTpwYmRldnRjYXRfMDFIVkgyNUI2RVBDWjBCODFOMUZKTUtTOTQ=',
      description:
        'Utah Olympic Park: 2002 Winter Olympics venue, training, year-round activities.',
      events: [
        {
          id: 'UHVibGlzaGVkRXZlbnQ6cGJkZXZ0XzAxSFkwSjdRTUpTTTJZNDRBWlJSMURWUUha',
          addresses: [
            {
              id: 'R2VuZXJpY0FkZHJlc3M6YWRkXzAxSFkwSjdRTU45S1RHOEdWWlgzNVlLOEZY',
              addressLine1: '3419 Olympic Parkway',
              city: 'Park City',
              country: 'United States',
              googlePlaceId: 'ChIJv0kuJgVsUocRhFXwW7C7DYs',
              lat: '40.7117726',
              long: '-111.5618448',
              state: 'Utah',
              __typename: 'GenericAddress',
            },
          ],
          imageUrl:
            'https://maps.googleapis.com/maps/api/place/photo?photo_reference=AUGGfZldFFit_oekI5zreyb908Fcy2U25yAqFkLSQ3nwileYU0sAHegJa58lslFbQvdgRha8r6s904Y9bwPpNvdKkinjfh6QHuemhUJYVhJ4E1AXsxQYu0RCUZVS_40HznfM1rzKcUo1Lg87YnHxllp1xUrJzoY0dM-zeGey7WQt1H72_4NW&key=AIzaSyCeX5qSPxuHc7zIxQ5y1taYLPe6hxj8LSc',
          name: 'Utah Olympic Park',
          notes:
            'The Utah Olympic Park, built for the 2002 Winter Olympics, is a renowned facility in Park City offering ski jumping, bobsledding, and other winter sports activities. It serves as a training center, hosts events, and provides opportunities for visitors to experience the thrill of these sports firsthand.',
          type: EventType.Experience,
          __typename: 'PublishedEvent',
        },
      ],
      name: 'What to do',
      __typename: 'GroupedEventCategory',
    },
  ],
  publishedEventCategories: [
    {
      id: 'UHVibGlzaGVkRXZlbnRDYXRlZ29yeTpwYmRldnRjYXRfMDFIVkgyNUI2RVBDWjBCODFOMUZKTUtTOTQ=',
      description: null,
      name: 'What to do',
      __typename: 'PublishedEventCategory',
    },
  ],
  __typename: 'Guide',
}
