import { DndContext } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { EmptyState } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { GuideOwner } from 'src/pages/guides/details/types'
import { GuideDraftSection } from './GuideDraftSection'
import { GuideDraftSectionsOverlay } from './GuideDraftSectionsOverlay'
import type { GuideDraftData } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { useGuideDraftDrag } from '../../useGuideDraftDrag'

interface GuideDraftSectionsProps {
  data: UseGetGuideDraftQuery['data']
  eventCount: number
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  owner: GuideOwner
  paginatedEventCategories: GuideDraftData['paginatedEventCategories']
  selectedId: string
}

export const GuideDraftSections = ({
  data,
  eventCount,
  onMapMarkerCenterChange,
  owner,
  paginatedEventCategories,
  selectedId,
}: GuideDraftSectionsProps) => {
  const {
    activeId,
    containers,
    dragProps,
    dropAnimation,
    isSortingContainer,
    items,
  } = useGuideDraftDrag(data)

  if (isEmpty(items)) return

  return (
    <div className='space-y-4 lg:space-y-6'>
      {!eventCount && (
        <div className='b-solid b-1px b-grey-300 bg-grey-100 rounded-2 p-x-3 p-y-3 lg:p-x-12 flex flex-row items-center gap-5 lg:gap-8'>
          <div className='lg:p-y-4 grow space-y-3'>
            <h4 className='type-h6'>Search and Add to your Guide!</h4>
            <p className='type-body-2'>
              Use the search bar above or zoom in on the map to find
              recommendations for your Guide. Add a note or description, then
              them into the editable sections below.
            </p>
          </div>
          <div className='p-y-2 min-w-19.5 lg:min-w-39 lg:p-y-0'>
            <EmptyState name='balloon' />
          </div>
        </div>
      )}
      <DndContext {...dragProps}>
        <SortableContext
          items={containers}
          strategy={verticalListSortingStrategy}
        >
          {containers?.map(container => (
            <GuideDraftSection
              key={items?.[container]?.id}
              isSortingContainer={isSortingContainer}
              owner={owner}
              paginatedEventCategories={paginatedEventCategories}
              paginatedEventCategory={items?.[container]}
              selectedId={selectedId}
              onMapMarkerCenterChange={onMapMarkerCenterChange}
            />
          ))}
        </SortableContext>
        <GuideDraftSectionsOverlay
          activeId={activeId}
          containers={containers}
          dropAnimation={dropAnimation}
          items={items}
          owner={owner}
          paginatedEventCategories={paginatedEventCategories}
        />
      </DndContext>
    </div>
  )
}
