import {
  Button,
  Carousel,
  Illustration,
  useScreenQuery,
} from '@travelpass/design-system'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { GuideCategory } from 'src/__generated__/graphql'
import {
  GuideRecommendedCard,
  GuideRecommendedCardLoading,
  useOnClickOwnerProfile,
} from 'src/common/components'
import { useGetPredefinedGuideTagsQuery } from 'src/common/hooks'
import { explorePath } from 'src/constants'
import { useGetCuratedGuides } from 'src/pages/home/useGetCuratedGuides'
import { KEYWORD_SEARCH_PHRASE } from '../keyword-search/constants'

export const Foods = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { tags } = useGetPredefinedGuideTagsQuery(true)
  const onClickOwnerProfile = useOnClickOwnerProfile()
  const { isMobileOrTablet, isDesktopScreen } = useScreenQuery()
  const { data, loading, error } = useGetCuratedGuides(GuideCategory.Food)

  const guides = data?.curatedGuides || []
  const showResults = !loading && !!guides.length

  const onChipClick = () => {
    const { id } = tags?.find(({ name }) => name == 'Food')
    setSearchParams(sp => {
      sp.set(KEYWORD_SEARCH_PHRASE, '')
      sp.set('tags', id)
      return sp
    })

    navigate({
      pathname: `${explorePath}/keyword`,
      search: searchParams.toString(),
    })
  }

  return (
    <div className='bg-warm-grey py-10 md:py-20'>
      <section className='max-w-300 mx-auto space-y-10'>
        <header className='space-y-3 text-balance text-left max-md:px-4 md:space-y-4'>
          <span className='type-overline text-grey-700 uppercase tracking-widest'>
            Foodie Guides
          </span>
          <h2 className='type-h1 capitalize'>See What&apos;s On The Menu</h2>
        </header>
        {error && (
          <div className='h-76 w-76 mx-auto'>
            <Illustration name='guide' />
            <p className='type-body-1 c-gray-400 text-center'>
              {error.message}
            </p>
          </div>
        )}
        {loading && (
          <ul
            aria-busy={loading}
            className='ps-none mt-18! grid list-none gap-4 max-md:mx-auto max-md:w-fit md:grid-cols-2 lg:grid-cols-3' // `max-md:mx-auto max-md:w-fit` are necessay due to loading card behavior of not stretching
          >
            <GuideRecommendedCardLoading />
            <GuideRecommendedCardLoading className='max-md:hidden' />
            <GuideRecommendedCardLoading className='max-lg:hidden' />
            <span className='sr-only'>Loading...</span>
          </ul>
        )}
        {isMobileOrTablet && showResults && (
          <Carousel
            borderRadius={12}
            hideArrows={true}
            keyBoardControl={true}
            size='small'
            slidesPerViewOnDesktop={2}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {guides?.map(guide => (
              <div
                key={guide.id}
                className='mx-auto mb-8 max-w-[calc(100%-24px)]'
              >
                <GuideRecommendedCard
                  asLink
                  guideRecommendedCardData={guide}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              </div>
            ))}
          </Carousel>
        )}
        {isDesktopScreen && showResults && (
          <ul className='ps-none mt-18 grid list-none gap-4 overflow-y-auto md:grid-cols-3'>
            {guides?.map(guide => (
              <li key={guide.id}>
                <GuideRecommendedCard
                  asLink
                  guideRecommendedCardData={guide}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              </li>
            ))}
          </ul>
        )}
        <div className='flex justify-center md:justify-end'>
          <Button label='View More' variant='outlined' onClick={onChipClick} />
        </div>
      </section>
    </div>
  )
}
