import { Helmet } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { GuideDraftTopNav } from '.'
import { GuideDraftTopNavOld } from './GuideDraftTopNavOld'
import { GuideEmpty } from '../../details/common'

export const GuideDraftEmpty = () => {
  const isGuidePrivatePublicEnabled = useFlag('guidePrivatePublic')

  return (
    <>
      <Helmet
        metaDescription='Discover must-see attractions, hidden gems, and the best places to eat with detailed info. Plan your unforgettable trip with our expert tips and personalized itineraries.'
        pageName='This guide could not be found'
      />
      {isGuidePrivatePublicEnabled ? (
        <GuideDraftTopNav guideDraftData={null} isUserOwner={false} />
      ) : (
        <GuideDraftTopNavOld guideDraftData={null} isUserOwner={false} />
      )}
      <GuideEmpty />
    </>
  )
}
