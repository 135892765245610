import {
  UserImageSource,
  type GetFeedFromCurrentUserQueryInDashboardQuery,
} from 'src/__generated__/graphql'
import type { DashboardFeedItem } from '../../types'

const initialDashboardFeedMock: GetFeedFromCurrentUserQueryInDashboardQuery['currentUser'] =
  {
    id: 'VXNlck5vZGU6dXNwcm9mXzAxSFRKNVBBUzhBWlQ0VkdYVkNSQlBSV1ZE',
    activityFeed: [
      {
        guide: {
          id: 'R3VpZGU6Z2RlXzAxSFZWQTNKTUFWMVRRSkYzMjEzQjNHN044',
          addresses: [
            {
              id: 'R2VuZXJpY0FkZHJlc3M6YWRkXzAxSjZGVEVDM1pHOE44VFk2NVdGWDVKTTBC',
              city: 'Las Vegas',
              country: 'United States',
              state: 'Nevada',
              __typename: 'GenericAddress',
            },
          ],
          description:
            'Immerse yourself in a vibrant destination where the allure of glamorous gaming halls meets the charm of cultural explorations. Offering a rich array of dining options and a diverse selection of places to stay, this destination promises an unforgettable experience catered to all tastes and budgets.',
          eventCounts: {
            numberEvents: 0,
            __typename: 'EventCounts',
          },
          images: [
            {
              id: 'VXNlckltYWdlOnVzaW1nXzAxSjZGVEVDM1FZMTM2ODQ2RFBEM1YwQzNL',
              source: UserImageSource.PixabayImage,
              url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01HKTEBEM3FYRH7TKK0F0DC7KM/guide_images/7d34668d-148e-49ab-8ecf-fa6b91bae205',
              __typename: 'UserImage',
            },
            {
              id: 'VXNlckltYWdlOnVzaW1nXzAxSjZGVEVDM1RBTThKTjhCTTBDNVpEWURY',
              source: UserImageSource.PixabayImage,
              url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01HKTEBEM3FYRH7TKK0F0DC7KM/guide_images/3c19ef54-cd27-4596-b2ad-513af421742e',
              __typename: 'UserImage',
            },
            {
              id: 'VXNlckltYWdlOnVzaW1nXzAxSjZGVEVDM1hWMlg5MjdFSlhINjY5WEZT',
              source: UserImageSource.PixabayImage,
              url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01HKTEBEM3FYRH7TKK0F0DC7KM/guide_images/b22b7b84-e1b7-4b0c-930c-53daafbbc042',
              __typename: 'UserImage',
            },
          ],
          imageUrl:
            'https://static.travelpass.com/assets/trip-state-pics/nevada.webp',
          insertedAt: '2024-04-19T18:37:30.487134Z',
          name: 'Las Vegas Itinerary Guide 1',
          ownerProfile: {
            id: 'VXNlclByb2ZpbGU6dXNwcm9mXzAxSFRKNVBBUzhBWlQ0VkdYVkNSQlBSV1ZE',
            accountHandle: 'alice',
            displayName: 'Alice',
            isFollowed: false,
            profileImageUrl:
              'https://lh3.googleusercontent.com/a/ALm5wu2OT9aLdGrKKiNn4IYSsEpQjaHB7dAKZBpOpuLD=s192-c',
            userId: 'VXNlcjp1c3JfMDFIS1RFQkVNM0ZZUkg3VEtLMEYwREM3S00=',
            __typename: 'UserProfile',
          },
          updatedAt: '2024-04-19T19:37:30.487134Z',
          timeZone: 'America/Los_Angeles',
          __typename: 'Guide',
        },
        __typename: 'FeedItem',
      },
      {
        guide: {
          id: 'R3VpZGU6Z2RlXzAxSFZWVENFWlExNDgzOEoxUlBHQjFBSjBU',
          addresses: [
            {
              id: 'R2VuZXJpY0FkZHJlc3M6YWRkXzAxSjZGVEROQ1o1NDNRMkJGRzIzVDJFOVlX',
              city: 'Los Angeles',
              country: 'United States',
              state: 'California',
              __typename: 'GenericAddress',
            },
          ],
          description: '',
          eventCounts: {
            numberEvents: 0,
            __typename: 'EventCounts',
          },
          images: [
            {
              id: 'VXNlckltYWdlOnVzaW1nXzAxSjVYTVNRODdURE4zRzY0NFdYSzYxRThE',
              source: UserImageSource.PixabayImage,
              url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01GEQ4ZPWFAT29ZDT1XVRY65R9/guide_images/7f0882fb-34ba-4a8a-a95f-deccdbcb494c',
              __typename: 'UserImage',
            },
            {
              id: 'VXNlckltYWdlOnVzaW1nXzAxSjVYTVNRODVSN1ZKREhaRkVZSE1LOFBa',
              source: UserImageSource.PixabayImage,
              url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01GEQ4ZPWFAT29ZDT1XVRY65R9/guide_images/24762dec-e8dd-48fb-ac39-4654138344dc',
              __typename: 'UserImage',
            },
            {
              id: 'VXNlckltYWdlOnVzaW1nXzAxSjVYTVNRODlOOU1GWFpXQzZTM0VBR0JX',
              source: UserImageSource.PixabayImage,
              url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01GEQ4ZPWFAT29ZDT1XVRY65R9/guide_images/8e95ee75-cba3-42d0-9ccd-be39d9b51daf',
              __typename: 'UserImage',
            },
          ],
          imageUrl:
            'https://static.travelpass.com/assets/trip-state-pics/california.webp',
          insertedAt: '2024-04-18T18:37:30.487134Z',
          name: 'Incredible Title',
          ownerProfile: {
            id: 'VXNlclByb2ZpbGU6dXNwcm9mXzAxSFRKNVBBUzhBWlQ0VkdYVkNSQlBSV1EA',
            accountHandle: 'bob',
            displayName: 'Bob',
            isFollowed: true,
            profileImageUrl:
              'https://static.travelpass.com/assets/users/default-avatar-lg.webp',
            userId: 'VXNlcjp1c3JfMDFIS1RFQkVNM0ZZUkg3VEtLMEYwREM3S00',
            __typename: 'UserProfile',
          },
          updatedAt: '2024-04-18T20:37:30.487134Z',
          timeZone: 'America/Los_Angeles',
          __typename: 'Guide',
        },
        __typename: 'FeedItem',
      },
    ],
  }

const dashboardFeedMock: DashboardFeedItem[] =
  initialDashboardFeedMock.activityFeed

export { dashboardFeedMock, initialDashboardFeedMock }
