import { useEffect, useState } from 'react'
import {
  Button,
  Illustration,
  ModalNext,
  useScreenQuery,
  useSnackbar,
  EmptyState,
  AvailableIllustrations,
} from '@travelpass/design-system'
import {
  CompetitionSortEnum,
  type UserProfile,
} from 'src/__generated__/graphql'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { getTracker } from 'src/utils'
import { ContestantCard } from './ContestantCard'
import { SortFilterSearch } from './SortFilterSearch'
import { VoteModalListener } from './VoteModalListener'
import { dispatchToggleVoteModal } from './dispatchToggleVoteModal'
import { useCompetitionLeaderboardQuery } from './hooks/useCompetitionLeaderboardQuery'
import { useCreateVoteMutation } from './hooks/useCreateVoteMutation'
import { useLeaderboardSearchParams } from './hooks/useLeaderboardSearchParams'
import { VideoPreview } from '../profile/content-section/guides/VideoPreview'
import { SignInModal } from '../signin'

const DEFAULT_VARIABLES = {
  first: 12,
  competitionLeaderboardRequest: {
    category: null,
    sortBy: CompetitionSortEnum.Popular,
    userProfile: null,
  },
}

export const CompetitionLeaderboard = () => {
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const { isAnonymous } = useFirebaseUser()
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')
  const { data, loading, error, fetchMore, refetch } =
    useCompetitionLeaderboardQuery(DEFAULT_VARIABLES)
  const { sortBy, userProfile } = useLeaderboardSearchParams()
  const [createVote] = useCreateVoteMutation()
  const { isMobileScreen } = useScreenQuery()

  const contestants = data?.competitionLeaderboard?.edges?.map(
    edge => edge.node
  )
  const cursor =
    data?.competitionLeaderboard?.pageInfo?.hasNextPage &&
    data?.competitionLeaderboard?.pageInfo.endCursor
  const isEmpty = !contestants || contestants.length === 0
  const isVideoModalOpen = !!videoUrl
  const noResults =
    (!contestants || contestants.length === 0) && !loading && !error

  useEffect(() => {
    refetch({
      competitionLeaderboardRequest: {
        category: null,
        sortBy,
        userProfile,
      },
    })
  }, [sortBy, userProfile])

  useEffect(() => {
    if (isSignInModalOpen && !isAnonymous) onClose()
  }, [isAnonymous, isSignInModalOpen])

  const onClose = () => {
    setIsSignInModalOpen(false)
    !isAnonymous &&
      addSuccessSnack({
        title: 'Successfully signed in!',
        subTitle: 'You may now vote for contestants',
      })
  }

  const onVoteClick = async (userProfile: UserProfile) => {
    if (isAnonymous) return setIsSignInModalOpen(true)
    else {
      try {
        const response = await createVote({
          variables: {
            input: {
              /** @desc the tracker field is nullable on the backend, however our getTracker util requires a string arg */
              tracker: getTracker(''),
              userProfileId: userProfile.id,
            },
          },
        })
        if (response?.data) {
          dispatchToggleVoteModal(userProfile)
        }
      } catch (e) {
        addErrorSnack({ title: 'Error', subTitle: e.message })
      }
    }
  }

  const handleProfileVideoClick = (videoUrl: string) => {
    setVideoUrl(videoUrl)
  }

  if (error) return <></>

  return (
    <div className='job-promotion space-y-40'>
      <SortFilterSearch />
      {noResults && (
        <section className='lg:pb-31 mx-auto w-fit space-y-9 px-6 pb-12'>
          <div className='max-w-186px max-h-137px mx-auto'>
            <Illustration name='resting' />
          </div>
          <p className='type-body-1 c-grey-800'>
            Sorry, it looks like we weren’t able to find any contestants
            matching your search.{' '}
          </p>
        </section>
      )}
      {contestants && !isEmpty && (
        <section className='max-w-340 mx-auto grid grid-cols-1 items-center gap-6 px-8 md:grid-cols-3 md:gap-y-12 lg:grid-cols-4'>
          {contestants.map(({ canVoteLeaderboard, userProfile, voteCount }) => {
            return (
              <div key={userProfile?.id} className='h-full w-full'>
                <ContestantCard
                  canVoteLeaderboard={canVoteLeaderboard}
                  handleProfileVideoClick={() =>
                    handleProfileVideoClick(userProfile?.introVideoEmbed)
                  }
                  userProfile={userProfile}
                  voteCount={voteCount}
                  onVoteClick={() => onVoteClick(userProfile)}
                />
              </div>
            )
          })}
        </section>
      )}
      {cursor && (
        <section className='my-12 flex justify-center'>
          <Button
            aria-label='Load next page of contestants'
            disabled={loading}
            size='large'
            variant='outlined'
            onClick={async () => {
              await fetchMore({
                variables: {
                  after: cursor,
                },
              })
            }}
          >
            View more
          </Button>
        </section>
      )}
      {isVideoModalOpen && (
        <ModalNext
          aria-label='Video Modal'
          size='medium'
          onClose={() => setVideoUrl('')}
        >
          <VideoPreview
            embedVideoLink={videoUrl}
            fullWidth={true}
            height={isMobileScreen ? '180' : '360'}
          />
        </ModalNext>
      )}
      {isSignInModalOpen && (
        <SignInModal initiallyShowCreateAccount={true} onClose={onClose} />
      )}
      <VoteModalListener />
    </div>
  )
}
