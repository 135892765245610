import { Button, Divider, useScreenQuery } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { useFlag } from 'src/common/hooks'
import type {
  FollowersModalType,
  GeneralLinksType,
  ProfileFields,
  SocialLinkType,
} from 'src/pages/profile/types'
import { BioSection } from './BioSection'
import { FollowersSection } from './FollowersSection'
import { GeneralLinks } from './GeneralLinks'
import { ProfileActionButtons } from './ProfileActionButtons'
import { ProfileAvatarAndHandle } from './ProfileAvatarAndHandle'
import { ReportProfileButton } from './Report/ReportProfileButton'
import { useIsProfileInCompetition } from './Report/useIsProfileInCompetition'
import { SocialLinks } from './SocialLinks'
import { Tags } from './Tags'
import { useCheckEditingMode } from '../components/hooks/useCheckEditingMode'
import { BragMetrics } from '../content-section/BragMetrics'
import { CompetitionVoteCta } from '../content-section/CompetitionVoteCta'
import { ProfileStatistics } from '../content-section/ProfileStatistics'
import { VideoPreview } from '../content-section/guides/VideoPreview'

interface ProfileInformationSectionProps {
  openEditingMode?: () => void
  profile: ProfileFields
  toggleModal?: (viewType: FollowersModalType) => void
}

export const ProfileInformationSection = ({
  profile,
  openEditingMode,
  toggleModal,
}: ProfileInformationSectionProps) => {
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const { isEditingMode } = useCheckEditingMode()
  const { isMobileOrTablet } = useScreenQuery()
  //Others
  const mobileOrEditMode = isEditingMode || isMobileOrTablet
  const mobileButNotEditMode = isMobileOrTablet && !isEditingMode
  const {
    bio,
    links,
    socialLinks,
    displayName,
    accountHandle,
    isUserOwner,
    profileImage: { url: profileImageUrl } = { url: undefined },
    bragStates,
    bragCountries,
    bragContinents,
    activeBannerImage,
    tags,
    introVideoEmbed,
  } = profile || {}

  const { data: userEnteredCompetition } = useIsProfileInCompetition(
    accountHandle,
    !enableCompetition2024
  )

  const dividerBaseRule = `w-21 mx-auto ${isEditingMode ? 'my-8' : ''}`
  const isBragMetricsOnlyChild =
    !bio &&
    (!socialLinks || isEmpty(socialLinks)) &&
    (!links || isEmpty(links)) &&
    (!tags || isEmpty(tags))

  const showVoteForCta = enableCompetition2024

  return (
    <div
      className={`${mobileOrEditMode ? 'rounded-5 lg:w-155 my-5 h-fit lg:mx-11' : 'max-w-60'} ${mobileButNotEditMode ? 'w-full' : ''}`}
    >
      <ProfileAvatarAndHandle
        hideEditButton
        accountHandle={accountHandle}
        bannerImage={activeBannerImage?.mediumUrl}
        displayName={displayName}
        profileImageUrl={profileImageUrl}
      />
      <section
        className={`flex flex-col space-y-6 pt-4 ${isEditingMode ? 'bg-white px-5' : ''}`}
      >
        {showVoteForCta && isMobileOrTablet && <CompetitionVoteCta />}

        {mobileOrEditMode && (
          <ProfileStatistics profile={profile} toggleModal={toggleModal} />
        )}
        <ProfileActionButtons
          isUserOwner={isUserOwner}
          profile={profile}
          onEdit={openEditingMode}
        />
      </section>
      <section
        className={`rounded-b-5 flex flex-col gap-y-6 ${isEditingMode ? 'pt-18 px-15 pb-33 bg-white' : 'pt-6'}`}
      >
        {mobileOrEditMode && (
          <div className='space-y-6'>
            <VideoPreview embedVideoLink={introVideoEmbed} />
            <BioSection bio={bio} textCenter={mobileOrEditMode} />
          </div>
        )}
        <FollowersSection profile={profile} toggleModal={toggleModal} />
        {socialLinks?.length > 0 && (
          <>
            <Divider
              className={classNames(dividerBaseRule, {
                hidden: isEmpty(socialLinks) && (!isUserOwner || isEditingMode),
              })}
            />
            <SocialLinks
              isUserOwner={isUserOwner}
              socialLinks={socialLinks as SocialLinkType}
            />
          </>
        )}
        {links?.length > 0 && (
          <>
            <Divider
              className={classNames(dividerBaseRule, {
                hidden: isEmpty(links) && (!isUserOwner || isEditingMode),
              })}
            />
            <GeneralLinks
              isUserOwner={isUserOwner}
              links={links as GeneralLinksType}
            />
          </>
        )}
        {tags?.length > 0 && (
          <>
            <Divider
              className={classNames(dividerBaseRule, {
                hidden: !tags || isEmpty(tags),
              })}
            />
            <Tags tags={tags} />
          </>
        )}
        {userEnteredCompetition && (
          <>
            <Divider className={dividerBaseRule} />
            <ReportProfileButton />
          </>
        )}
        {mobileOrEditMode && (
          <>
            <Divider
              className={`${dividerBaseRule} ${isBragMetricsOnlyChild ? 'hidden' : ''}`}
            />
            <div className='space-y-6'>
              <p className='type-h4 text-center'>I&apos;ve visited</p>
              <BragMetrics
                justifyCenter
                metrics={[
                  { label: 'Continents', value: bragContinents },
                  { label: 'Countries', value: bragCountries },
                  { label: 'States', value: bragStates },
                ]}
              />
            </div>
          </>
        )}
      </section>
    </div>
  )
}
