import { useEffect, useState } from 'react'
import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import isEmpty from 'lodash.isempty'
import { gql } from 'src/__generated__'
import type {
  GetGuideQueryInGuideDetailsQueryVariables,
  LatLong,
} from 'src/__generated__/graphql'
import { GuideStatus } from 'src/__generated__/graphql'
import { constructMapCenter } from 'src/common/components/Map'
import {
  getGuideDraftDetailsUrl,
  getGuideOwnerImage,
  getGuideOwnerName,
  getGuideOwnerUrl,
} from 'src/utils'
import { getGuideMetaData } from './guideUtils'
import type { GuideData, GuideMetaData, GuideOwner } from './types'

const getGuideQuery = gql(`
  query GetGuideQueryInGuideDetails($id: ID!) {
    node(id: $id) {
      ... on Guide {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        collectedCount
        description
        eventCounts {
          numberEvents
        }
        guideDraft {
          id
        }
        images {
          id
          source
          url
        }
        imageUrl # remove when images are added
        insertedAt
        isCollected
        isPublishingV2Ready
        name
        numberOfLikes
        ownerProfile {
          id
          accountHandle
          displayName
          isFollowed
          isUserOwner
          profileImageUrl
          userId
        }
        shareCount
        status
        tags {
          id
          name
        }
        updatedAt
        timeZone
        viewCount
      }
    }
  }
`)

type UseGetGuideQuery = {
  guideData: GuideData
  guideDraftUrl: string
  guideMetaData: GuideMetaData
  hasError: ApolloError
  isLoading: boolean
  location: LatLong
  owner: GuideOwner
}

export const useGetGuideQuery = (
  id: GetGuideQueryInGuideDetailsQueryVariables['id']
): UseGetGuideQuery => {
  const [pollInterval, setPollInterval] = useState(0)
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getGuideQuery, {
    pollInterval,
    skip: !id,
    variables: {
      id,
    },
  })
  const guideData = data?.node as GuideData
  const { addresses, eventCounts, images, imageUrl, name, ownerProfile } =
    guideData ?? {}
  const [address] = addresses ?? []
  const { accountHandle, displayName, isUserOwner, profileImageUrl } =
    ownerProfile ?? {}
  const { lat, lng } = constructMapCenter(address?.lat, address?.long)
  const guideDraftUrl = getGuideDraftDetailsUrl({
    id: guideData?.guideDraft?.id,
    isUserOwner,
    name: name,
  })
  const owner: GuideOwner = {
    image: getGuideOwnerImage(profileImageUrl),
    isUserOwner,
    name: getGuideOwnerName({
      accountHandle,
      displayName,
    }),
    url: getGuideOwnerUrl(accountHandle),
  }
  const guideMetaData = getGuideMetaData({
    address: addresses?.[0],
    image: images?.[0]?.url ?? imageUrl,
    name,
    numberEvents: eventCounts?.numberEvents,
    ownerName: owner?.name,
  })

  useEffect(() => {
    if (!isEmpty(data))
      setPollInterval(
        (data?.node as GuideData)?.status === GuideStatus.AwaitingCompletion
          ? 30000
          : 0
      )
  }, [data])

  return {
    guideData,
    guideDraftUrl,
    guideMetaData,
    hasError,
    isLoading,
    location: {
      latitude: lat,
      longitude: lng,
    },
    owner,
  }
}
