import { useQuery, type ApolloError } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetDashboardFeedSuggestedTravelpassGuidesQueryInDashboardQuery } from 'src/__generated__/graphql'

const officialTravelpassGuidesQuery = gql(`
  query GetDashboardFeedSuggestedTravelpassGuidesQueryInDashboard($first: Int = 20) {
    officialTravelpassGuides(first: $first) {
      edges {
        node {
          id
          addresses {
            id
            city
            country
            state
          }
          images {
            id
            url
          }
          imageUrl
          name
          ownerProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`)

type UseGetDashboardFeedSuggestedTravelpassGuidesQuery = {
  dashboardFeedSuggestedTravelpassGuideData: GetDashboardFeedSuggestedTravelpassGuidesQueryInDashboardQuery['officialTravelpassGuides']['edges']
  hasError: ApolloError
  isLoading: boolean
}

const useGetDashboardFeedSuggestedTravelpassGuidesQuery =
  (): UseGetDashboardFeedSuggestedTravelpassGuidesQuery => {
    const {
      data,
      error: hasError,
      loading: isLoading,
    } = useQuery(officialTravelpassGuidesQuery, {
      variables: {},
    })

    return {
      dashboardFeedSuggestedTravelpassGuideData:
        data?.officialTravelpassGuides?.edges,
      hasError,
      isLoading,
    }
  }

export type { UseGetDashboardFeedSuggestedTravelpassGuidesQuery }
export { useGetDashboardFeedSuggestedTravelpassGuidesQuery }
