import { useQuery, type ApolloError } from '@apollo/client'
import { gql } from 'src/__generated__'
import { type GetDashboardFeedSuggestedFollowersQueryInDashboardQuery } from 'src/__generated__/graphql'

const dashboardFeedSuggestedFollowersQuery = gql(`
  query GetDashboardFeedSuggestedFollowersQueryInDashboard {
    currentUser {
      id
      userProfile {
        id
        userFollowRecommendations {
          id
          accountHandle
          displayName
          followerFollowingCount {
            countOfFollowers
          }
          guideCount
          isFollowed
          profileImageUrl
          userId
        }
      }
    }
  }
`)

type UseGetDashboardFeedSuggestedFollowersQuery = {
  dashboardFeedSuggestedFollowersData: GetDashboardFeedSuggestedFollowersQueryInDashboardQuery['currentUser']['userProfile']['userFollowRecommendations']
  hasError: ApolloError
  isLoading: boolean
}

const useGetDashboardFeedSuggestedFollowersQuery =
  (): UseGetDashboardFeedSuggestedFollowersQuery => {
    const {
      data,
      error: hasError,
      loading: isLoading,
    } = useQuery(dashboardFeedSuggestedFollowersQuery)

    return {
      dashboardFeedSuggestedFollowersData:
        data?.currentUser?.userProfile?.userFollowRecommendations,
      hasError,
      isLoading,
    }
  }

export type { UseGetDashboardFeedSuggestedFollowersQuery }
export { useGetDashboardFeedSuggestedFollowersQuery }
