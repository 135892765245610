import { useState } from 'react'
import { Button, useSnackbar } from '@travelpass/design-system'
import type { ButtonSize, ButtonVariant } from '@travelpass/design-system'
import { getGuideMetricString } from './'
import type { GuideData } from '../../details/types'
import { useIncrementGuideShareCountMutation } from '../../useIncrementGuideShareCountMutation'
import { useShareGuide } from '../../useShareGuide'

interface GuideMetricShareProps {
  guideData: Pick<GuideData, 'id' | 'name' | 'shareCount' | 'ownerProfile'>
  hideLabel?: boolean
  isDisabled?: boolean
  size?: ButtonSize
  variant?: ButtonVariant
}

export const GuideMetricShare = ({
  guideData,
  hideLabel = false,
  isDisabled = false,
  size = 'medium',
  variant = 'text',
}: GuideMetricShareProps) => {
  const shareGuide = useShareGuide()
  const [incrementShareCount] = useIncrementGuideShareCountMutation()
  const { addSuccessSnack } = useSnackbar()
  const [hasShared, setHasShared] = useState(false)
  const { id, name, shareCount, ownerProfile } = guideData ?? {}
  const { isUserOwner } = ownerProfile ?? {}

  const onShareGuide = async () => {
    await shareGuide({
      guideDraftTitle: name,
      isUserOwner,
      shareUrl: window.location.href,
      onSuccessfulShare: () => {
        addSuccessSnack({ timeout: 1000, title: 'Guide URL copied' })
      },
    })
    if (!hasShared) {
      incrementShareCount({ variables: { guideId: id } })
      setHasShared(true)
    }
  }

  return (
    <Button
      aria-label='Share this guide'
      isDisabled={isDisabled}
      size={size}
      startIcon='iosShare'
      variant={variant}
      onClick={onShareGuide}
    >
      {getGuideMetricString(shareCount, hideLabel ? '' : 'share')}
    </Button>
  )
}
