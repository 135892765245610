import { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Icon,
  Link,
  TextArea,
  useSnackbar,
} from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import { useGetGuideHotelLazyQuery } from 'src/pages/guides/details'
import { onGuideSessionStorageSelectedIdChange } from 'src/pages/guides/details/useGuideSessionStorageIds'
import { Hero } from 'src/pages/trips/components/TripDrawerContent/components'
import { useGetTripPlaceDetailsLazyQuery } from 'src/pages/trips/hooks'
import { getEventImage } from 'src/pages/trips/utils'
import {
  constructAddress,
  encodeGuideId,
  generateHotelDetailsUrl,
} from 'src/utils'
import { GuideDraftDrawerContentLoading } from './GuideDraftDrawerContentLoading'
import type { GuideDraftPublishedEvent } from '../../types'
import { useArchiveGuideDraftEventMutation } from '../../useArchiveGuideDraftEventMutation'
import { useUpdateGuideDraftEventMutation } from '../../useUpdateGuideDraftEventMutation'
import { GuideDraftEventDeleteModal } from '../GuideDraftEventDeleteModal'

const guideDraftEventNoteMaxLength = 3000

interface GuideDraftDrawerContentProps {
  selectedEvent: GuideDraftPublishedEvent
}

export const GuideDraftDrawerContent = ({
  selectedEvent,
}: GuideDraftDrawerContentProps) => {
  const [archivePublishedEvent] = useArchiveGuideDraftEventMutation()
  const isGuideUrlShortEnabled = useFlag('guideUrlShort')
  const [getGuideDraftHotel] = useGetGuideHotelLazyQuery()
  const [getPlaceDetails, { data, error: hasError, loading: isLoading }] =
    useGetTripPlaceDetailsLazyQuery()
  const { guideDraftId } = useParams()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [notes, setNotes] = useState(selectedEvent?.notes ?? '')
  const [websiteText, setWebsiteText] = useState('View Website')
  const [updatePublishedEvent] = useUpdateGuideDraftEventMutation()
  const {
    address: addressFirstLine,
    city,
    country,
    imageLinks,
    name,
    postalCode: zipcode,
    standardHotelId,
    state,
  } = data?.getPlaceDetails ?? {}
  const { addresses, id, notes: initialNotes } = selectedEvent ?? {}
  const googlePlaceId = addresses?.[0]?.googlePlaceId
  const address = constructAddress({
    addressFirstLine,
    city,
    country,
    state,
    zipcode,
  })
  const images = imageLinks?.slice(0, 5)?.map(image => getEventImage(image))

  useEffect(() => {
    setWebsiteText('View Website')
  }, [id])

  useEffect(() => {
    setNotes(initialNotes ?? '')
  }, [initialNotes])

  useEffect(() => {
    if (!googlePlaceId) return

    try {
      getPlaceDetails({
        variables: {
          includeImageLinks: true,
          placeDetailsRequest: {
            isHotelSearch: true,
            placeId: googlePlaceId,
          },
        },
      })
    } catch (error) {
      console.error(error)
    }
  }, [googlePlaceId])

  const onViewWebsiteClick = async () => {
    try {
      let website = ''

      if (standardHotelId) {
        const response = await getGuideDraftHotel({
          variables: {
            hotelId: standardHotelId.toString(),
          },
        })
        const { id, city, name, state, stateCode } =
          response?.data?.lodging ?? {}
        website = generateHotelDetailsUrl({
          city,
          id,
          name,
          state,
          stateAbbreviation: stateCode,
        })
      } else {
        const response = await getPlaceDetails({
          variables: {
            includeWebsite: true,
            includeImageLinks: true,
            placeDetailsRequest: {
              placeId: googlePlaceId,
            },
          },
        })
        website = response?.data?.getPlaceDetails?.website
      }

      if (website) window.open(website)

      if (!website) setWebsiteText('No Website Found')
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  const onDelete = async () => {
    try {
      if (!!notes && !isDeleteModalOpen) {
        setIsDeleteModalOpen(true)
        return
      }
      await archivePublishedEvent({
        variables: {
          archivePublishedEventInput: {
            publishedEventId: id,
          },
        },
      })
      setIsDeleteModalOpen(false)
      onGuideSessionStorageSelectedIdChange('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event deleted',
      })
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  const onSubmit = async () => {
    try {
      await updatePublishedEvent({
        variables: {
          publishedEventInput: {
            guideDraftId: encodeGuideId({
              guideId: guideDraftId,
              isGuideDraft: true,
              isGuideUrlShortEnabled,
            }),

            notes,
            publishedEventId: id,
          },
        },
      })
      onGuideSessionStorageSelectedIdChange('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event updated',
      })
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  if (isLoading) return <GuideDraftDrawerContentLoading />

  if (hasError) return <div>Error...</div>

  return (
    <>
      <div className='p-b-8 grow space-y-8 overflow-y-auto md:space-y-12'>
        <Hero hideArrows={false} images={images} />
        <section className='p-x-8 space-y-8 md:space-y-10'>
          <header className='space-y-2'>
            <div className='space-y-1'>
              <h2 className='type-h6 line-clamp-1'>{name}</h2>
              <p className='color-grey-800 type-body-2 flex flex-row gap-1'>
                <span className='color-orange lg:p-t-0.25'>
                  <Icon name='placeOutline' size='small' />
                </span>
                <span>{address}</span>
              </p>
            </div>
            {websiteText && (
              <Link
                aria-label={websiteText}
                isDisabled={websiteText === 'No Website Found'}
                onClick={onViewWebsiteClick}
              >
                {websiteText}
              </Link>
            )}
            <Divider className='m-t-1' />
          </header>
          <TextArea
            aria-label='Add a note'
            helperText={`${notes.length}/${guideDraftEventNoteMaxLength} characters`}
            label='Note'
            maxLength={guideDraftEventNoteMaxLength}
            placeholder='Add notes, expert advice, or a quick description about this recommendation.'
            rows={15}
            value={notes ?? ''}
            onInput={event => setNotes(event.currentTarget.value)}
          />
        </section>
      </div>
      <div className='border-grey300 border-t-1 shadow-1 flex flex-row items-center justify-center gap-4 border-0 border-solid bg-white px-8 py-6'>
        <Button
          fullWidth
          aria-label='Delete event from guide'
          startIcon='deleteOutline'
          variant='outlined'
          onClick={onDelete}
        >
          Delete
        </Button>
        <Button fullWidth aria-label='Save event note' onClick={onSubmit}>
          Save
        </Button>
      </div>
      {isDeleteModalOpen && (
        <GuideDraftEventDeleteModal
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  )
}
