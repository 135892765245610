import { useEffect, useState } from 'react'
import { Button, SkeletonDots, Link } from '@travelpass/design-system'
import dayjs from 'dayjs'
import { FormProvider } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  BookingQuestionEnum,
  CardType,
  NoncePaymentType,
} from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  useLucencyNumber,
  onPhoneNumberClick,
} from 'src/config/analytics/lucencyUtils'
import {
  bookSegment,
  experiencesPath,
  tripTimelinePath,
} from 'src/constants/routes'
import EmptyState from 'src/pages/hotels/book/assets/EmptyState.png'
import { findCardType } from 'src/pages/hotels/book/utils/bookingUtils'
import { TripSearchParams, DrawerType } from 'src/pages/trips/constants'
import { useCreateExperienceBookingMutation } from './useCreateExperienceBookingMutation'
import { useExperienceBookingForm } from './useExperienceBookingForm'
import type { BookingFormSubmitHandler } from './useExperienceBookingForm'
import { useUserGuestInfoQuery } from './useUserGuestInfoQuery'

enum EXPERIENCES_BOOKING_ERROR {
  NO_BOOKING_ID = 'NO_BOOKING_ID',
  GENERIC = 'GENERIC',
}

interface ExperienceHoldDataObject {
  [key: string]: number | string
}

export const ExperiencesBookingForm = ({
  isPickupRequired,
  productCode,
  experienceHoldData,
  ...props
}: {
  isPickupRequired: boolean
  productCode: string
  children: React.ReactNode
  className?: string
  experienceHoldData?: ExperienceHoldDataObject
}) => {
  const { bookingFlowId: bookingStateId } = useParams()
  const formId = `experience-booking-form-${bookingStateId}`
  const { methods, clear: clearPersistedForm } =
    useExperienceBookingForm(formId)
  const [bookingError, setBookingError] = useState<EXPERIENCES_BOOKING_ERROR>()
  const clearBookingError = () => setBookingError(null)
  const braintreePayment = methods.watch('braintreePayment')
  const [createExperienceBooking, { loading, error }] =
    useCreateExperienceBookingMutation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { data } = useUserGuestInfoQuery()
  const guestInfo = data?.currentUser
  const { lucencyNumber } = useLucencyNumber()
  const supportNumber = lucencyNumber()

  // prepopulate guest fields with user info
  useEffect(() => {
    if (!guestInfo) return

    const [fname, lname, phone, email] = methods.getValues([
      'bookerInfo.firstName',
      'bookerInfo.lastName',
      'communication.phone',
      'communication.email',
    ])

    if (!fname && guestInfo.firstName) {
      methods.setValue('bookerInfo.firstName', guestInfo.firstName)
    }
    if (!lname && guestInfo.lastName) {
      methods.setValue('bookerInfo.lastName', guestInfo.lastName)
    }
    if (!phone && guestInfo.phoneNumber) {
      methods.setValue('communication.phone', guestInfo.phoneNumber)
    }
    if (!email && guestInfo.email) {
      methods.setValue('communication.email', guestInfo.email)
    }
  }, [guestInfo])

  useEffect(() => {
    if (braintreePayment) {
      // once payment is authorized, create the booking
      const values = methods.getValues()
      handleCreateExperienceBooking(values)
    }
  }, [braintreePayment])

  const handleCreateExperienceBooking: BookingFormSubmitHandler =
    async data => {
      if (loading) return
      try {
        const { bookerInfo, answers, braintreePayment, languageGuide } = data

        const formattedPhone = `+1${data.communication.phone}` // TODO: what if not US? what format is correct?

        const communication = {
          ...data.communication,
          phone: formattedPhone,
        }

        const payment = getPaymentInput({
          braintreePayment,
          creditCard: {
            ...data.creditCardSensitive,
            ...data.creditCard,
          },
          phone: formattedPhone,
        })

        const flattenedBookingQuestionAnswers = []
          .concat(...Object.values(answers))
          .flat()
          /* filter out questions that have no answer */
          .filter(bqa => Boolean(bqa.answer))

        const hasPickupPoint =
          flattenedBookingQuestionAnswers.findIndex(
            ({ question }) => question === BookingQuestionEnum.PickupPoint
          ) !== -1

        /**
         * When a product option that does not include a pickup service for a product that requests the PICKUP_POINT question, the following answer should be sent:
         * See: https://partnerresources.viator.com/travel-commerce/merchant/implementing-booking-questions/
         */
        if (isPickupRequired && !hasPickupPoint) {
          flattenedBookingQuestionAnswers.push({
            question: BookingQuestionEnum.PickupPoint,
            answer: 'MEET_AT_DEPARTURE_POINT',
            unit: 'LOCATION_REFERENCE',
          })
        }

        const tripId = searchParams.get('tripId')
        const eventId = searchParams.get(TripSearchParams.eventId)

        const result = await createExperienceBooking({
          variables: {
            input: {
              experienceBookingRequest: {
                bookingStateId,
                bookerInfo,
                communication,
                bookingQuestionAnswers: flattenedBookingQuestionAnswers,
                eventId,
                payment,
                tripId,
                ...(languageGuide && { languageGuide }),
                productCode,
              },
            },
          },
        })

        const experienceBooking =
          result?.data?.createExperienceBooking?.experienceBooking

        if (
          experienceBooking?.externalConfirmationId &&
          experienceBooking?.id
        ) {
          const { externalConfirmationId, id, product, trip } =
            experienceBooking
          clearPersistedForm()

          const { id: eventId } = trip?.events.find(event =>
            event.experienceBooking.some(
              booking =>
                booking?.externalConfirmationId === externalConfirmationId
            )
          )

          if (experienceHoldData) {
            const {
              category_id,
              experience,
              experience_address,
              experience_city,
              experience_country,
              experience_postal_code,
              experience_state,
              end_date,
              item_list_name,
              location,
              start_date,
              start_time,
              title,
              total_price,
              total_quantity,
            } = experienceHoldData

            pushDataToDataLayer('experienceProductPurchased', {
              booking: id,
              category_id,
              experience,
              experience_address,
              experience_city,
              experience_country,
              experience_postal_code,
              experience_state,
              end_date,
              item_list_name,
              location,
              start_date,
              start_time,
              title,
              total_price,
              total_quantity,
            })
          }

          if (eventId && tripId) {
            navigate(
              `${tripTimelinePath}/${tripId}?eventId=${eventId}&eventType=${DrawerType.InternalExperienceBooked}&showBookingConfirmationModal=true`
            )
          } else {
            navigate(
              `${experiencesPath}/${bookSegment}/confirmation/${externalConfirmationId}/${id}?tripId=${tripId}`
            )
          }
        } else {
          /* handle when booking.id doesn't exist. */
          setBookingError(EXPERIENCES_BOOKING_ERROR.NO_BOOKING_ID)
        }
      } catch (error) {
        console.error(error)
        setBookingError(EXPERIENCES_BOOKING_ERROR.GENERIC)
      }
    }

  if (loading)
    return (
      <div className='my-10 flex-col items-center text-center'>
        <h3 className='text-h3'>We are confirming your booking...</h3>
        <p className='text-subtitle1 mb-6'>
          This could take a few minutes, so please don&apos;t refresh your
          browser or use the back button.
        </p>
        <SkeletonDots />
      </div>
    )

  if (bookingError) {
    return (
      <div className='py-4 text-center'>
        <h3 className='text-h3'>Booking Error!</h3>
        {bookingError === EXPERIENCES_BOOKING_ERROR.GENERIC && (
          <>
            <p className='text-subtitle-1'>
              Shoot! It looks your booking failed.
              <br />
              <br />
              We were unable to process your request. Please review the
              information you provided on the checkout page or try picking a new
              time or experience option.
            </p>
            <img
              alt='Empty state'
              className='h-30vh rounded object-contain'
              loading='lazy'
              src={EmptyState}
            />
            <div className='mt-4 flex flex-col items-center gap-2'>
              <Button label='Try Again' onClick={clearBookingError} />
              <Button
                label='Back to Experience Details'
                variant='outlined'
                onClick={() => navigate(-1)}
              />
            </div>
          </>
        )}

        {bookingError === EXPERIENCES_BOOKING_ERROR.NO_BOOKING_ID && (
          <>
            <div className='text-subtitle1 my-6'>
              There was an error completing this booking. Please contact support
              at{' '}
              <Link
                href={`tel:${supportNumber}`}
                label={supportNumber}
                onClick={() => onPhoneNumberClick(supportNumber)}
              />{' '}
              and reference{' '}
              <pre className='font-mono'>
                Booking State Id: {bookingStateId}
              </pre>
              .
            </div>
            <img
              alt='Empty state'
              className='h-30vh rounded object-contain'
              loading='lazy'
              src={EmptyState}
            />
            <div className='mt-4 flex flex-col items-center gap-2'>
              <Button
                label='Back to Experience Details'
                variant='outlined'
                onClick={() => navigate(-1)}
              />
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <form
        {...props}
        id={formId}
        onSubmit={methods.handleSubmit(handleCreateExperienceBooking)}
      />
    </FormProvider>
  )
}

const getPaymentInput = ({ braintreePayment, creditCard, phone }) => {
  if (braintreePayment) {
    const { nonce, details } = braintreePayment
    return {
      nonce: {
        token: nonce,
        type: NoncePaymentType.PaypalAccount,
        paypalAccount: {
          email: details.email,
          payerId: details.payerId,
        },
        lastName: details.lastName,
        firstName: details.lastName,
        country: details.countryCode,
        address1: details?.billingAddress?.line1,
        address2: details?.billingAddress?.line2,
        city: details?.billingAddress?.city,
        stateProvince: details?.billingAddress?.state,
        postalCode: details?.billingAddress?.postalCode,
      },
    }
  } else {
    const { expiration, ...card } = creditCard
    const expirationDate = dayjs(expiration, 'MMYY')
    const detectedCardType = CardType[findCardType(card.number)]

    return {
      creditCard: {
        ...card,
        phone,
        expirationMonth: expirationDate.format('MM'),
        expirationYear: expirationDate.format('YYYY'),
        cardType: detectedCardType,
      },
    }
  }
}
