import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import isEmpty from 'lodash.isempty'
import { useParams, useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import type { Options, RoomFilters } from 'src/__generated__/graphql'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { useLucencyNumber } from 'src/config/analytics/lucencyUtils'
import { getRateOptions, checkIsNativeAppUser } from 'src/utils'
import { HotelRoomsApolloLoading } from './HotelRoomsApolloLoading'
import { createRateRequest } from './hotelRoomsApolloUtils'
import { constructHotelId } from '../../../utils'
import { HotelEmpty } from '../../HotelEmpty'
import { HotelRoomsList } from '../HotelRoomsList'

const HOTEL_ROOMS = gql(`
  query HotelRoomsHotelRoomDetails($rateRequest: FlattenedRateRequest!) {
    mappedHotelRoomDetails(rateRequest: $rateRequest) {
      rooms {
        amenities
        beds {
          count
          type
        }
        description
        images {
          caption
          href
        }
        name
        occupancyType
        rates {
          currencyCode
          cancelationPolicy {
            fullyRefundableUntil
            nonRefundableAfter
            shortText
            partiallyRefundableUntil
            policyText
            providerOverrideText
            refundType
          }
          category {
            supplier
            type
          }
          dueLater
          allInTotal {
            amount
            currency
          }
          grandtotal
          maxOccupancy
          nightlyAverage
          rateToken
          subtotal
          standardAttributes
          strikethrough
        }
      }
    }
  }
`)

interface HotelRoomsApolloProps {
  filters: RoomFilters
  onSetdisabledOnLoading?(isDisabled: boolean): void
}

export const HotelRoomsApollo = ({
  filters,
  onSetdisabledOnLoading,
}: HotelRoomsApolloProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { hotelId } = useParams()
  const { isAnonymous } = useFirebaseUser()
  const [options, setOptions] = useState<Options>(null)
  const [isValid, setIsValid] = useState(true)
  const standardHotelId = constructHotelId(hotelId)
  const isNativeAppUser = checkIsNativeAppUser()
  const willSkip = !options
  const { lucencyNumber } = useLucencyNumber()

  const excludedProviders = useMemo(
    () => searchParams.get('excludeProvider')?.split(','),
    []
  )

  useEffect(() => {
    setOptions(getRateOptions(isAnonymous, isNativeAppUser))
  }, [isAnonymous, isNativeAppUser])

  const constructOptions = (): Options => {
    if (excludedProviders) {
      return { ...options, excludeProviders: excludedProviders }
    }

    return options
  }

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(HOTEL_ROOMS, {
    skip: willSkip,
    variables: {
      ...createRateRequest({
        filters,
        standardHotelId,
        options: constructOptions(),
        searchParams,
      }),
    },
  })

  const rooms = data?.mappedHotelRoomDetails?.rooms

  useEffect(() => onSetdisabledOnLoading(isLoading), [isLoading])
  useEffect(() => {
    if (isEmpty(rooms)) {
      setIsValid(false)
    } else if (!isLoading) {
      const updatedHotelRoomsData = rooms?.filter(
        ({ images }) => !!images?.length
      )

      setIsValid(!!updatedHotelRoomsData?.length)

      if (updatedHotelRoomsData?.length) {
        // set/update lucency - since it is ordered by price, we are getting the first room/rate
        const lowestRate = parseFloat(rooms[0]?.rates[0]?.nightlyAverage)
        // set it on url to be used on the modal
        searchParams.set('lowestRate', lowestRate.toString())
        setSearchParams(searchParams, {
          replace: true,
        })
        const hasCugRates = rooms.reduce((accumulatedRates, room) => {
          const rates = room.rates.filter(
            ({ category }) => category?.type == 'CUG'
          )
          return [...accumulatedRates, ...rates]
        }, [])
        lucencyNumber({
          averageOrderValue: lowestRate || 0,
          ratesAvailability: 'available',
          cugAvailability: hasCugRates.length > 0 ? 'available' : 'unavailable',
        })
      } else {
        lucencyNumber({
          averageOrderValue: 0,
          ratesAvailability: 'unavailable',
          cugAvailability: 'unavailable',
        })
      }
    }
  }, [rooms])

  if (isLoading || willSkip) return <HotelRoomsApolloLoading />
  if (hasError || !isValid) {
    if (!!filters?.bedType || !!filters?.refundable)
      return (
        <HotelEmpty
          subtitle='Try removing some filters.'
          title="We're sorry, there are no rooms with all of those options."
        />
      )
    return (
      <HotelEmpty
        subtitle='Try searching for other dates.'
        title="We're sorry, it looks like there are no rooms available for this hotel."
      />
    )
  }

  return <HotelRoomsList hotelRoomsData={rooms} />
}
