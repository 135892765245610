import { useEffect, useState } from 'react'
import kountSDK from '@kount/kount-web-client-sdk'
import load from 'load-script'
import { useLucencyNumber } from 'src/config/analytics/lucencyUtils'
import { env, Environment, lucencyIdFromCookie } from 'src/utils'

const expediaFraudUrlObject = {
  int: 'https://trust-lab.expedia.com/trustService/getTrustWidget?pid=4a3b85f4-e64e-41dc-bbd9-e5690bef2af9',
  stg: 'https://trust-lab.expedia.com/trustService/getTrustWidget?pid=4a3b85f4-e64e-41dc-bbd9-e5690bef2af9',
  prod: 'https://trust.expedia.com/trustService/getTrustWidget?pid=4a3b85f4-e64e-41dc-bbd9-e5690bef2af9',
}

const webBedsFraudUrlObject = {
  int: 'https://cdn.webbeds.com/js/payment/test.js',
  stg: 'https://cdn.webbeds.com/js/payment/live.js',
  prod: 'https://cdn.webbeds.com/js/payment/live.js',
}

const expediaFraudUrl = expediaFraudUrlObject[env]
const webBedsFraudUrl = webBedsFraudUrlObject[env]

export const useFraudProtection = () => {
  const [webbedsDevicePayload, setWebbedsDevicePayload] = useState<
    string | null
  >(null)
  const { lucencyNumber } = useLucencyNumber()

  // Expedia Fraud Protection
  useEffect(() => {
    const trustApi = { clientConfiguration: {} }

    trustApi.clientConfiguration = {
      websessionProviderMethod: 'JS',
      webSessionProviderMethodParam: lucencyIdFromCookie,
      placement: 'PURCHASE',
      placementPage: '1',
      reportingSegment: window.location.origin,
    }

    // @ts-ignore
    window.trustApi = trustApi

    load(expediaFraudUrl, () => {})
  }, [])

  // Kount Fraud Protection
  useEffect(() => {
    const kountConfig = {
      clientID: 100281,
      environment: env === Environment.PROD ? 'PROD' : 'TEST',
      isSinglePageApp: true,
      collectBehaviorData: true,
    }
    // kount expects a 32 character string to be passed in
    // removing the dashes in lucencyId will make it 32 characters
    const sessionID = lucencyIdFromCookie.replace(/-/g, '')
    kountSDK(kountConfig, sessionID)
  }, [])

  // WebBeds Fraud Protection
  useEffect(() => {
    load(webBedsFraudUrl, () => {
      window.webbeds.onDataCollect(payload => {
        setWebbedsDevicePayload(payload)
        lucencyNumber({ webbedsDevicePayload: payload })
      })
    })
  }, [])

  return { webbedsDevicePayload }
}
