import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { getDashboardFeedItemOld } from './dashboardUtils'
import type { DashboardFeedItemOld } from './types'

const currentUserQuery = gql(`
  query GetFeedFromCurrentUserQueryInDashboardOld {
    currentUser {
      id
      activityFeed(feedCount: 5) {
        title
        follower {
          accountHandle
          displayName
          id
          followCount {
            countOfFollowed
            countOfFollowing
          }
          profileImageUrl
        }
        guide {
          id
          description
          insertedAt
          name
          ownerProfile {
            id
            displayName
            profileImageUrl
          }
          timeZone
        }
        hotelRecommendation {
          id
          hotelInfo {
            city
            description
            state
          }
          name
          notes
          standardHotelId
          userProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
        }
        likedListGuide {
          guide {
            id
            addresses {
              id
              city
              state
            }
            description
            guideType
            name
          }
          likerProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
        }
      }
    }
  }
`)

type UseGetDashboardFeedQueryOld = {
  dashboardFeedData: DashboardFeedItemOld[]
  hasError: ApolloError
  isLoading: boolean
}

const useGetDashboardFeedQueryOld = (): UseGetDashboardFeedQueryOld => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(currentUserQuery)
  const dashboardFeedData =
    data?.currentUser?.activityFeed?.map(feedItem =>
      getDashboardFeedItemOld(feedItem)
    ) ?? []

  return {
    dashboardFeedData,
    hasError,
    isLoading,
  }
}

export type { UseGetDashboardFeedQueryOld }
export { useGetDashboardFeedQueryOld }
