import {
  Avatar,
  Button,
  ModalNext,
  useScreenQuery,
  useSnackbar,
} from '@travelpass/design-system'
import type { ToggleVoteModalProps } from './dispatchToggleVoteModal'
import { useFollowMutation } from '../profile/components/hooks/useFollowMutation'

interface YouVotedModalProps {
  onClose: VoidFunction
  userProfile: ToggleVoteModalProps
}

export const YouVotedModal = ({ onClose, userProfile }: YouVotedModalProps) => {
  const { isTabletScreen, isDesktopScreen } = useScreenQuery()
  const { accountHandle, displayName, isFollowed, profileImageUrl, userId } =
    userProfile ?? {}
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [follow] = useFollowMutation({})
  const variables = { input: { userId } }

  const onFollowClick = async () => {
    if (isFollowed) {
      window.open(`/profile/${accountHandle}`, '_blank')
      onClose()
    } else {
      try {
        const response = await follow({ variables })
        if (response?.data)
          addSuccessSnack({
            title: `You are now following ${displayName}!`,
          })
      } catch (e) {
        return addErrorSnack({
          title: 'An error occurred',
          subTitle: e.message,
        })
      } finally {
        onClose()
      }
    }
  }

  return (
    <ModalNext
      className='text-center'
      size='medium'
      title={`You voted for ${displayName}!`}
      onClose={onClose}
    >
      <div className='space-y-8'>
        <img
          alt=''
          className='-mr-5 hidden md:inline'
          src='https://static.travelpass.com/assets/job-campaign-2024/confetti-left.svg'
        />
        <Avatar size='xl' src={profileImageUrl} />
        <img
          alt=''
          className='-ml-5 hidden md:inline'
          src='https://static.travelpass.com/assets/job-campaign-2024/confetti-right.svg'
        />
        <section className='space-y-2.5'>
          <h6 className='type-h6'>Thanks for voting!</h6>
          <p className='type-body-1'>
            You can also give them a follow to help them win!
          </p>
        </section>
        <div className='flex flex-row-reverse justify-center gap-2 py-8'>
          <Button
            aria-label={`Follow ${displayName}'s profile`}
            size={
              isDesktopScreen ? 'large' : isTabletScreen ? 'medium' : 'small'
            }
            startIcon='personOutline'
            variant='filled'
            onClick={onFollowClick}
          >
            {isFollowed ? 'View Profile' : 'Follow'}
          </Button>
          <Button
            size={
              isDesktopScreen ? 'large' : isTabletScreen ? 'medium' : 'small'
            }
            variant='outlined'
            onClick={onClose}
          >
            Done
          </Button>
        </div>
      </div>
    </ModalNext>
  )
}
