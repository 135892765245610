import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { DashboardFeedItem } from './types'

const currentUserQuery = gql(`
  query GetFeedFromCurrentUserQueryInDashboard {
    currentUser {
      id
      activityFeed(feedCount: 100) {
        guide {
          id
          addresses {
            id
            city
            country
            state
          }
          description
          eventCounts {
            numberEvents
          }
          insertedAt
          images {
            id
            source
            url
          }
          imageUrl
          name
          ownerProfile {
            id
            accountHandle
            displayName
            isFollowed
            profileImageUrl
            userId
          }
          updatedAt
          timeZone
        }
        title
      }
    }
  }
`)

type UseGetDashboardFeedQuery = {
  dashboardFeedData: DashboardFeedItem[]
  dashboardFeedGuideData: DashboardFeedItem['guide'][]
  hasError: ApolloError
  isLoading: boolean
}

const useGetDashboardFeedQuery = (): UseGetDashboardFeedQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(currentUserQuery)
  const dashboardFeedData = data?.currentUser?.activityFeed ?? []
  const dashboardFeedGuideData = dashboardFeedData?.reduce(
    (total, current) => {
      if (current?.guide?.id && total?.length < 20) total.push(current?.guide)

      return total
    },
    [] as DashboardFeedItem['guide'][]
  )

  return {
    dashboardFeedData,
    dashboardFeedGuideData,
    hasError,
    isLoading,
  }
}

export type { UseGetDashboardFeedQuery }
export { useGetDashboardFeedQuery }
