import { useState } from 'react'
import { IconButton } from '@travelpass/design-system'
import { ButtonLink } from 'src/common/components'

export const HomeCompetitionBanner = () => {
  const [showCompetitionBanner, setShowCompetitionBanner] = useState(true)

  return (
    showCompetitionBanner && (
      <div className='sm:h-105 z-3 fixed bottom-0 flex w-full bg-transparent md:h-80'>
        <div className='sm:h-89.5 md:h-47.5 lg:h-54.5 md:pl-125 lg:pl-165 absolute bottom-0 z-0 flex w-full bg-white'>
          <div className='sm:h-89.5 md:h-47.5 lg:h-54.5 absolute left-0 w-full overflow-hidden'>
            <img
              alt=''
              className='sm:max-h-35 md:max-h-45 lg:max-h-72.5 absolute sm:left--3 sm:top-20 md:left-0 md:top--10'
              src='https://static.travelpass.com/assets/job-campaign-2024/hero-squiggle-top.webp'
            />
            <img
              alt=''
              className='sm:h-25 md:h-50 absolute right-0 z-0 sm:bottom-0 sm:top-auto md:bottom-auto md:top-8'
              src='https://static.travelpass.com/assets/job-campaign-2024/hero-squiggle-bottom-mobile.png'
            />
          </div>
          <div className='job-promotion z-1 sm:mt-35 flex flex-col justify-center gap-4 sm:px-8 md:mb-4 md:mt-auto md:px-0'>
            <h3 className='type-h3 c-forestDark'>Win A Trip Of A Lifetime!</h3>
            <span className='type-body-1 c-grey800'>
              You could win a $20,000 dream vacation! To enter, just set up a
              free account, create travel Guides, and get votes.{' '}
            </span>
            <div className='flex'>
              <ButtonLink to='/competition/about' variant='forest'>
                Learn More
              </ButtonLink>
            </div>
          </div>
          <div className='absolute right-3 top-3 sm:hidden md:flex'>
            <IconButton
              icon='clear'
              onClick={() => setShowCompetitionBanner(false)}
            />
          </div>
        </div>
        <img
          alt=''
          className='z-2 sm:bottom-50 md:bottom-unset sm:max-w-90% md:max-w-unset lg:left-30 absolute object-contain sm:mx-5 md:mx-0'
          loading='lazy'
          src='https://static.travelpass.com/assets/job-campaign-2024/home-competition-banner.webp'
        />
        <div className='top-15 z-3 absolute right-0 md:hidden'>
          <IconButton
            icon='clear'
            onClick={() => setShowCompetitionBanner(false)}
          />
        </div>
      </div>
    )
  )
}
