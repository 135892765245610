import { Button, Illustration } from '@travelpass/design-system'
import type { AvailableIllustrations } from '@travelpass/design-system'

interface DashboardCtaProps {
  buttonText: string
  description: string
  illustration: AvailableIllustrations
  onClick: VoidFunction
  title: string
}

export const DashboardCta = ({
  buttonText,
  description,
  illustration,
  onClick,
  title,
}: DashboardCtaProps) => {
  return (
    <div className='flex flex-row items-center gap-4'>
      <div className='min-w-21.5 w-21.5'>
        <Illustration name={illustration} />
      </div>
      <div className='grow space-y-2.5'>
        <h6 className='type-h6 color-forest-light'>{title}</h6>
        <p className='type-body-2 color-grey-700'>{description}</p>
        <Button
          aria-label={title}
          endIcon='arrowForward'
          size='small'
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}
