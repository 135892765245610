import { ParticipantStatus } from 'src/__generated__/graphql'

export const userCompetitionReadyState = (
  status: ParticipantStatus,
  isUserOwner: boolean
) => {
  /**@todo do we need to show a CTA if isUserOwner && ParticipantStatus.ready? */
  if (status === ParticipantStatus.Ready && !isUserOwner) {
    return ParticipantStatus.Ready
  }
  if (status === ParticipantStatus.Entered && isUserOwner) {
    return ParticipantStatus.Entered
  }
  return null
}
