import { useEffect, useRef } from 'react'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
} from '@travelpass/design-system'
import { FormProvider } from 'react-hook-form'
import { type ProfileByAccountHandleQuery } from 'src/__generated__/graphql'
import { DashboardProfileInfoAbout } from './DashboardProfileInfoAbout'
import { DashboardProfileInfoEmbedYoutubeVideo } from './DashboardProfileInfoEmbedYoutubeVideo'
import { DashboardProfileInfoHeader } from './DashboardProfileInfoHeader'
import { DashboardProfileInfoLinks } from './DashboardProfileInfoLinks'
import { DashboardProfileInfoSocialMediaLinks } from './DashboardProfileInfoSocialMediaLinks'
import { DashboardProfileInfoTags } from './DashboardProfileInfoTags'
import { DashboardProfileInfoTravelTracker } from './DashboardProfileInfoTravelTracker'
import { useDashboardProfileInfo } from '../../../useDashboardProfileInfo'

interface DashboardProfileInfoModalProps {
  onDismiss: VoidFunction
  onModalAvatarOpen: VoidFunction
  onModalBannerOpen: VoidFunction
  profile: ProfileByAccountHandleQuery['viewUserProfile']
  scrollId?: string
}

export const DashboardProfileInfoModal = ({
  onDismiss,
  onModalAvatarOpen,
  onModalBannerOpen,
  profile,
  scrollId,
}: DashboardProfileInfoModalProps) => {
  const { methods, onSubmit } = useDashboardProfileInfo({
    onDismiss,
    profile,
  })
  const ref = useRef<HTMLFormElement>(null)

  useEffect(() => {
    requestAnimationFrame(() => {
      if (scrollId) {
        const element = document.getElementById(scrollId)
        element?.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }, [scrollId])

  return (
    <FormProvider {...methods}>
      <Modal title='Edit Profile' onDismiss={onDismiss}>
        <ModalScrollContents>
          <form
            className='p-x-2 md:p-x-0 relative space-y-8'
            ref={ref}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <DashboardProfileInfoHeader
              profile={profile}
              onModalAvatarOpen={onModalAvatarOpen}
              onModalBannerOpen={onModalBannerOpen}
            />
            <DashboardProfileInfoAbout />
            <DashboardProfileInfoTags />
            <DashboardProfileInfoTravelTracker />
            <DashboardProfileInfoEmbedYoutubeVideo />
            <DashboardProfileInfoSocialMediaLinks />
            <DashboardProfileInfoLinks />
          </form>
        </ModalScrollContents>
        <ModalActions>
          <Button
            autoFocus={true}
            type='submit'
            onClick={methods.handleSubmit(onSubmit)}
          >
            Save Profile
          </Button>
          <Button variant='outlined' onClick={onDismiss}>
            Cancel
          </Button>
        </ModalActions>
      </Modal>
    </FormProvider>
  )
}
