import { useState } from 'react'
import { Avatar } from '@travelpass/design-system'
import { FollowButton } from 'src/pages/profile/components/FollowButton/FollowButton'
import { getProfilePageUrl } from 'src/pages/profile/components/profileUtils'
import type { UseGetDashboardFeedSuggestedFollowersQuery } from '../../useGetDashboardFeedSuggestedFollowersQuery'

interface DashboardFeedSuggestedFollowerProps {
  follower: UseGetDashboardFeedSuggestedFollowersQuery['dashboardFeedSuggestedFollowersData'][0]
}

/** @todo tests */
export const DashboardFeedSuggestedFollower = ({
  follower,
}: DashboardFeedSuggestedFollowerProps) => {
  const {
    accountHandle,
    displayName,
    followerFollowingCount,
    guideCount,
    isFollowed,
    profileImageUrl,
    userId,
  } = follower ?? {}
  const ownerUrl = `${window.location.origin}${getProfilePageUrl(follower?.accountHandle)}`
  const [showFollowButton, setShowFollowButton] = useState(true)

  if (!accountHandle) return

  return (
    <div
      className='w-30 md:w-35 flex select-none flex-col items-center gap-3'
      data-testid='DashboardFeedSuggestedFollower'
    >
      <a
        className='no-underline'
        href={ownerUrl}
        rel='noreferrer'
        target='_blank'
      >
        <div className='hidden md:block'>
          <Avatar border='sm' size='lg' src={profileImageUrl} />
        </div>
        <div className='md:hidden'>
          <Avatar border='sm' size='md' src={profileImageUrl} />
        </div>
      </a>
      <div className='flex flex-col items-center space-y-1'>
        <h5 className='type-h6-mobile md:type-h5-desktop text-center'>
          <a
            className='c-forest-light no-underline'
            href={ownerUrl}
            rel='noreferrer'
            target='_blank'
          >
            {accountHandle}
          </a>
        </h5>
        <div className='flex flex-row items-center gap-2'>
          <p className='c-forest m-0 flex flex-col text-center'>
            <span className='type-body-1-medium-mobile md:type-button-desktop'>
              {followerFollowingCount?.countOfFollowers}
            </span>
            <span className='type-subtext md:type-small-text-desktop'>
              Followers
            </span>
          </p>
          <p className='c-forest m-0 flex flex-col text-center'>
            <span className='type-body-1-medium-mobile md:type-button-desktop'>
              {guideCount}
            </span>
            <span className='type-subtext md:type-small-text-desktop'>
              Guides
            </span>
          </p>
        </div>
      </div>
      {!isFollowed && (
        /** @todo remove this as soon as the query for SuggestedFollowers is ready */
        /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
        <div className='min-h-8' onClick={() => setShowFollowButton(false)}>
          {showFollowButton && (
            <div className='hidden md:block'>
              <FollowButton
                displayName={displayName}
                fullWidth={false}
                isFollowed={isFollowed}
                label='Follow'
                userId={userId}
                variant='outlined'
              />
            </div>
          )}
          <div className='md:hidden'>
            {showFollowButton && (
              <FollowButton
                displayName={displayName}
                fullWidth={false}
                isFollowed={isFollowed}
                label='Follow'
                userId={userId}
                variant='outlined'
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
