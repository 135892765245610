import { Icon } from '@travelpass/design-system'
import { GuideImage } from 'src/pages/guides/details/common'
import { getGuideAddress, getGuideDetailsUrl } from 'src/utils'
import { DashboardFeedCard } from './DashboardFeedCard'
import { DashboardFeedCardHeaderGuide } from './DashboardFeedCardHeaderGuide'
import { getDashboardFeedGuideImages } from '../../../dashboardUtils'
import type { DashboardFeedItem } from '../../../types'

/** @todo remove mock when BE is ready */
const latestUpdateMock = {
  updateGroup: {
    events: { eventsUpdateCount: 5 },
    images: { imagesUpdateCount: 3 },
    notes: { notesUpdateCount: 2 },
  },
}

interface DashboardFeedCardRepublishedGuideGroupedProps {
  dashboardFeedItem: DashboardFeedItem['guide']
}

export const DashboardFeedCardRepublishedGuideGrouped = ({
  dashboardFeedItem,
}: DashboardFeedCardRepublishedGuideGroupedProps) => {
  const { addresses, description, eventCounts, id, name } =
    dashboardFeedItem ?? {}
  const { numberEvents: guideEventsTotal } = eventCounts ?? {}
  const address = getGuideAddress({
    city: addresses?.[0]?.city,
    country: addresses?.[0]?.country,
    state: addresses?.[0]?.state,
  })
  /** @todo when BE is ready, get images from guide.latestUpdate.images */
  const images = getDashboardFeedGuideImages({
    images: dashboardFeedItem?.images,
    imageUrl: dashboardFeedItem?.imageUrl,
  })
  const url = getGuideDetailsUrl({
    id,
    name,
  })
  const { updateGroup } = latestUpdateMock
  const { events, images: imagesUpdate, notes } = updateGroup
  const eventsUpdateCount = events?.eventsUpdateCount
  const imagesUpdateCount = imagesUpdate?.imagesUpdateCount
  const notesUpdateCount = notes?.notesUpdateCount

  return (
    <DashboardFeedCard href={url}>
      <article>
        <DashboardFeedCardHeaderGuide dashboardFeedItem={dashboardFeedItem}>
          <p className='type-body-2 flex flex-row items-center gap-1'>
            <Icon name='checkCircleOutline' size='small' />
            <span className='min-w-fit'>Updated this Guide</span>
          </p>
        </DashboardFeedCardHeaderGuide>
        <div className='space-y-3'>
          <div className='h-27 lg:h-50 pointer-events-none flex flex-row items-center gap-1'>
            {images.map(({ url }, index) => (
              <GuideImage key={index} src={url} />
            ))}
          </div>
          <div className='space-y-4'>
            <div className='space-y-2'>
              <h2 className='type-body-1-medium-mobile type-subtitle-1-desktop line-clamp-2'>
                {name}
              </h2>
              <div className='flex flex-row items-center gap-3'>
                {address && (
                  <div className='flex flex-row items-center gap-2'>
                    <Icon name='placeOutline' size='small' />
                    <p className='type-body-2 c-black'>{address}</p>
                  </div>
                )}
                {address && !!guideEventsTotal && (
                  <div className='c-grey-700'>|</div>
                )}
                {!!guideEventsTotal && (
                  <p className='type-body-2 c-black'>
                    {guideEventsTotal}{' '}
                    {guideEventsTotal > 1 ? 'events' : 'event'}
                  </p>
                )}
              </div>
              {description && (
                <p className='type-body-2 c-black md:hidden lg:line-clamp-3'>
                  {description}
                </p>
              )}
            </div>
            <div className='[&>div>i]:c-canyon hidden flex-row flex-wrap items-center gap-6 md:flex'>
              {notesUpdateCount && (
                <p className='flex flex-row items-center gap-2'>
                  <Icon name='stickyNote2' />
                  <span className='type-subtitle-2-desktop c-forest-dark'>
                    {notesUpdateCount}{' '}
                    <span className='hidden md:inline'>new</span>{' '}
                    {notesUpdateCount > 1 ? 'notes' : 'note'}
                  </span>
                </p>
              )}
              {imagesUpdateCount && (
                <p className='flex flex-row items-center gap-2'>
                  <Icon name='camera' />
                  <span className='type-subtitle-2-desktop c-forest-dark'>
                    {imagesUpdateCount}{' '}
                    <span className='hidden md:inline'>new </span>
                    {imagesUpdateCount > 1 ? 'photos' : 'photo'}
                  </span>
                </p>
              )}
              {eventsUpdateCount && (
                <p className='flex flex-row items-center gap-2'>
                  <Icon name='placeOutline' />
                  <span className='type-subtitle-2-desktop c-forest-dark'>
                    {eventsUpdateCount}{' '}
                    <span className='hidden md:inline'>new </span>
                    {eventsUpdateCount > 1 ? 'events' : 'event'}
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
      </article>
    </DashboardFeedCard>
  )
}
