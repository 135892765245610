import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { MobileBottomNav } from 'src/common/components/MobileBottomNav'
import { useFlag } from 'src/common/hooks'
import { FavoriteIdeas } from './components/FavoriteIdeas'
import { PlanGetInspired } from './components/PlanGetInspired'
import { PlanHeader } from './components/PlanHeader'
import { UniqueTrip } from './components/UniqueTrip'
import { ProfileModalListener } from '../guides/results/ProfileModalListener'

export const Plan = () => {
  const isMobileBottomNavEnabled = useFlag('mobileBottomNav')

  return (
    <div className='pb-10 md:pb-20'>
      <PlanHeader />
      <UniqueTrip />
      <FavoriteIdeas />
      <PlanGetInspired />
      {isMobileBottomNavEnabled && <MobileBottomNav />}
      <AddToCollectionsModalListener />
      <ProfileModalListener />
    </div>
  )
}
