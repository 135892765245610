import { Icon, Link } from '@travelpass/design-system'
import { useLucencyNumber } from 'src/config/analytics/lucencyUtils'
import { type StayEvent } from '../constants'

export const StayBottom = ({ data }: { data: StayEvent | null }) => {
  const { lucencyNumber } = useLucencyNumber()
  const constructedLucencyPhoneNumber = lucencyNumber()
  const browserPhone = data?.phone?.replace(/[^0-9]/g, '') ?? ''

  return (
    <div className='space-y-8 px-8 py-8'>
      <div>
        <h2 className='type-h6'>Contact Hotel</h2>
        <p className='type-body-2 pt-2'>
          For questions about check-in, property details, and amenities please
          contact your hotel directly.
        </p>
        {data?.phone && (
          <div className='c-newForest flex flex-row items-start justify-start gap-2 pt-4'>
            <Icon name='phone' size='small' />
            <Link
              className='line-clamp-1 text-nowrap'
              draggable='false'
              href={`tel:${browserPhone}`}
            >
              {browserPhone}
            </Link>
          </div>
        )}
      </div>

      <div>
        <h2 className='type-h6'>Contact Travelpass Group</h2>
        <p className='type-body-2 pt-2'>
          For any reservation question, contact Travelpass directly or check out
          our FAQ&apos;s.
        </p>
        <div className='c-newForest flex flex-row items-start justify-start gap-2 pt-4'>
          <Icon name='phone' size='small' />
          <Link
            className='line-clamp-1 text-nowrap'
            draggable='false'
            href={`tel:${constructedLucencyPhoneNumber}`}
            rel='noopener'
          >
            {constructedLucencyPhoneNumber}
          </Link>
        </div>
      </div>
    </div>
  )
}
