import { guideMock } from 'src/pages/guides/details/__tests__/__mocks__'
import type { DashboardFeedSuggestedGuideItem } from '../../types'

/** @todo replace mocked data (waiting on BE) */
export const dashboardFeedSuggestedGuideMock: DashboardFeedSuggestedGuideItem[] =
  Array(10)
    .fill(0)
    .map((_, index) => ({
      node: {
        ...guideMock,
        id: `${guideMock.id}-${index}`,
        __typename: 'Guide',
      },
      __typename: 'GuideEdge',
    }))
