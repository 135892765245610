import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLucencyNumber } from 'src/config/analytics/lucencyUtils'
import { SupportFaq } from './SupportFaq'
import { SupportHero } from './SupportHero'
import { SupportReviews } from './SupportReviews'
import { faqItemsData } from '../common/components'
import type { FaqItemFuseResult } from '../common/components'
import { generateFuseObject, searchFuseObject } from '../infoUtils'

const fuseObject = generateFuseObject()

export const Support = () => {
  const navigate = useNavigate()
  const [indices, setIndices] = useState([])
  const [items, setItems] = useState<FaqItemFuseResult[]>()
  const [searchTerm, setSearchTerm] = useState('')
  const { lucencyNumber } = useLucencyNumber()

  useEffect(() => {
    fuseObject.setCollection(faqItemsData(navigate, lucencyNumber()))
    setItems(searchFuseObject(fuseObject, ''))
    onChange(searchTerm)
  }, [])

  const onChange = (updatedSearchTerm: string) =>
    setSearchTerm(updatedSearchTerm)

  const onIndicesChange = (updatedIndices: number[]) =>
    setIndices(updatedIndices)

  const onSubmit = (willReset = false) => {
    if (willReset) setSearchTerm('')

    setIndices([])
    setItems(searchFuseObject(fuseObject, willReset ? '' : searchTerm))
  }

  return (
    <div data-testid='Support'>
      <SupportHero
        searchTerm={searchTerm}
        onChange={onChange}
        onSubmit={onSubmit}
      />
      <SupportFaq
        indices={indices}
        items={items}
        onIndicesChange={onIndicesChange}
      />
      <SupportReviews />
    </div>
  )
}
