import { useEffect, useRef, useState } from 'react'
import {
  Button,
  Divider,
  Modal,
  SkeletonDots,
  useSnackbar,
} from '@travelpass/design-system'
import type { Area } from 'react-easy-crop'
import { useFormContext } from 'react-hook-form'
import {
  PresignedUrlType,
  ProfileImageSource,
  VisibilityLevel,
} from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { firebaseCheckConnectedProviders } from 'src/config/firebase/firebaseUtils'
import { UploadAvatarModalButtons } from './UploadAvatarModalButtons'
import { DEFAULT_PROFILE_IMAGE_URL } from '../../profileConstants'
import { ImageCropper } from '../ImageCropper'
import { useGetPresignedUrl } from '../hooks/useGetPresignedUrl'
import { useGetProfile } from '../hooks/useGetProfile'
import { useUpsertCurrentUserProfile } from '../hooks/useUpsertCurrentUserProfile'
import { convertUrlToFile, getCroppedImg } from '../profileUtils'

interface UploadModalProps {
  onClose?: () => void
  avatar: string
}

export const UploadAvatarModal = ({ onClose, avatar }: UploadModalProps) => {
  //State
  const [file, setFile] = useState(null)
  const [previewUrl, setPreviewUrl] = useState('') // New state for the preview image
  const [isDraggingOver, setIsDraggingOver] = useState(false) // New state for drag-over indication
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [profileImageSource, setProfileImageSource] =
    useState<ProfileImageSource>(null)
  //Hooks
  const { isConnectedToGoogle } = firebaseCheckConnectedProviders()
  const { profile, loading: isLoading } = useGetProfile()
  const { setValue } = useFormContext()
  const [getPresignedUrl] = useGetPresignedUrl()
  const [updateProfileInfo, { loading: isUpdateProfileInfoLoading }] =
    useUpsertCurrentUserProfile()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const fileInputRef = useRef(null)

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file)
      setPreviewUrl(objectUrl)
      // Cleanup: Revoke the object URL when it's no longer needed
      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [file])

  useEffect(() => {
    if (croppedAreaPixels?.width) {
      getCroppedImg(previewUrl || avatar, croppedAreaPixels).then(img =>
        setCroppedImage(img)
      )
    }
  }, [croppedAreaPixels])

  useEffect(() => {
    setProfileImageSource(profile?.profileImageSource)
  }, [profile])

  const handleDragOver = e => {
    e.preventDefault()
    setIsDraggingOver(true) // Update state to indicate drag-over
  }

  const handleDragLeave = e => {
    e.preventDefault()
    setIsDraggingOver(false) // Reset state when leaving the drop area
  }

  const handleDrop = e => {
    e.preventDefault()
    setIsDraggingOver(false) // Reset state on drop
    const files = e.dataTransfer.files
    if (files.length) setFile(files[0])
  }

  const updatePresignedURL = async () => {
    try {
      const croppedFile = await convertUrlToFile(croppedImage)
      const updatedFile = croppedFile

      if (!updatedFile) return onClose()

      const presignedUrlResponse = await getPresignedUrl({
        // Seems like we need to disable the cache here to get the latest presigned url
        fetchPolicy: 'no-cache',
        variables: {
          presignedUrlType: PresignedUrlType.ProfileImages,
        },
      })
      const presignedUrl = presignedUrlResponse.data?.getPresignedUrl?.url
      const profileImageUrl = presignedUrl?.split('?')[0]
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: updatedFile, // The file to upload
        headers: {
          'Content-Type': 'image/jpeg', // or the appropriate file type, e.g., 'image/png'
        },
      })

      if (response.ok) {
        if (profileImageUrl) {
          updatePhoto(profileImageUrl, ProfileImageSource.UserUploadedImage)
        } else {
          updatePhoto(
            DEFAULT_PROFILE_IMAGE_URL,
            ProfileImageSource.DefaultImage
          )
        }
      } else addErrorSnack({ title: 'Upload failed' })
    } catch (error) {
      addErrorSnack({ title: 'Upload failed' })
    }
  }

  const updatePhoto = async (
    imageUrl: string,
    imageSource: ProfileImageSource
  ) => {
    const res = await updateProfileInfo({
      variables: {
        input: {
          userProfileRequest: {
            accountHandle: profile?.accountHandle,
            visibilityLevel: VisibilityLevel.Public,
            profileImageUrl: imageUrl,
            profileImageSource: imageSource,
            profileTagIds: profile?.tags?.map(tag => tag?.id) || [],
          },
        },
      },
    })
    if (res.data) {
      setValue(
        'profileImageUrl',
        res?.data?.upsertProfile?.userProfile?.profileImage?.url
      )
      setValue(
        'profileImageSource',
        res?.data?.upsertProfile?.userProfile?.profileImage?.source
      )
      addSuccessSnack({ title: 'Uploaded profile image successfully' })
      onClose()
    }
  }

  const onCropComplete = (croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onDelete = async () => {
    if (profileImageSource == ProfileImageSource.ClaimsImage) {
      await updatePhoto(avatar, ProfileImageSource.ClaimsImage)
    } else {
      await updatePhoto(
        DEFAULT_PROFILE_IMAGE_URL,
        ProfileImageSource.DefaultImage
      )
    }
  }

  if (isLoading) return

  return (
    <Modal size='medium' title='Change your profile photo' onDismiss={onClose}>
      <div
        className={`drop-area flex flex-col items-center pb-10 ${isDraggingOver ? 'bg-pink' : ''}`} // Conditional class application
        onDragLeave={handleDragLeave} // Handle drag leave to reset the state
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {profileImageSource != ProfileImageSource.ClaimsImage ? (
          <ImageCropper
            image={previewUrl || avatar}
            onCropComplete={onCropComplete}
          />
        ) : (
          <img
            alt='profileImage'
            className='max-h-50 max-w-50 border-12 mb-2 rounded-full border-solid border-white object-cover'
            id='profilePicture'
            src={previewUrl || avatar}
          />
        )}
        <p className='type-body-1 pb-6 pt-6 text-gray-700'>
          {profileImageSource == ProfileImageSource.ClaimsImage
            ? `Looks like your photo is coming from ${isConnectedToGoogle ? 'Google' : 'Facebook'}. If you would like to change the size or position, please upload a new photo.`
            : 'Edit your photo by moving or adjusting the size of the circle.'}
        </p>
        <UploadAvatarModalButtons
          fileInputRef={fileInputRef}
          profileImageSource={profileImageSource}
          onDelete={onDelete}
        />
        <input
          accept='image/*'
          ref={fileInputRef}
          style={{ display: 'none' }}
          type='file'
          onChange={e => {
            setFile(e.target.files[0])
            setProfileImageSource(ProfileImageSource.UserUploadedImage)
          }}
        />
      </div>
      <Divider />
      <div className='flex justify-center pt-6'>
        <div className='md:w-35% w-70%'>
          {isUpdateProfileInfoLoading ? (
            <div className='flex min-h-10 flex-col justify-center'>
              <SkeletonDots />
            </div>
          ) : (
            <Button
              fullWidth
              label='Save'
              variant='filled'
              onClick={() => updatePresignedURL()}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}
