import { today } from '@travelpass/design-system'
import dayjs from 'dayjs'
import type {
  FeedItem,
  GetFeedFromCurrentUserQueryInDashboardOldQuery,
} from 'src/__generated__/graphql'
import { profilePath } from 'src/constants'
import { getDateFromUTC, getGuideDetailsUrl } from 'src/utils'
import type { DashboardFeedItem, DashboardFeedItemOld } from './types'
import { getProfilePageUrl } from '../profile/components/profileUtils'
import { DEFAULT_PROFILE_IMAGE_URL } from '../profile/profileConstants'

const getDashboardFeedGuideImages = ({
  images,
  imageUrl,
}: Pick<DashboardFeedItem['guide'], 'images' | 'imageUrl'>) => {
  if (images?.length) return images?.slice(0, 3)

  return [
    {
      url: imageUrl,
    },
  ]
}

const getDashboardFeedItemOld = (
  feedItem: GetFeedFromCurrentUserQueryInDashboardOldQuery['currentUser']['activityFeed'][0]
): DashboardFeedItemOld => {
  const { follower, guide, likedListGuide, hotelRecommendation, title } =
    feedItem ?? {}

  if (follower?.id) {
    const {
      accountHandle,
      followCount,
      id: productId,
      profileImageUrl,
    } = follower ?? {}
    const { countOfFollowed, countOfFollowing } = followCount ?? {}

    return {
      countOfFollowed: countOfFollowed ?? 0,
      countOfFollowing: countOfFollowing ?? 0,
      description: '',
      productId,
      profileImageUrl: profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL,
      title,
      url: `${window.location.origin}${profilePath}/${accountHandle}`,
    }
  }

  if (guide?.id) {
    const { description, id, insertedAt, name, ownerProfile, timeZone } =
      guide ?? {}
    const { profileImageUrl } = ownerProfile ?? {}
    let date = ''

    try {
      date = getDateFromUTC(insertedAt, timeZone).format('DD MMMM YYYY')
    } catch (error) {
      console.error(error)
    }

    const url = `${window.location.origin}${getGuideDetailsUrl({
      id,
      name,
    })}`

    return {
      date,
      description,
      productId: id,
      profileImageUrl: profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL,
      title,
      url,
    }
  }

  if (hotelRecommendation?.id) {
    const {
      hotelInfo,
      notes,
      standardHotelId: productId,
      userProfile,
    } = hotelRecommendation ?? {}
    const { city, description, state } = hotelInfo ?? {}
    const { profileImageUrl, accountHandle } = userProfile ?? {}
    const location = city && state ? `${city}, ${state}` : ''
    const url = `${getProfilePageUrl(accountHandle)}?tab=topHotels`

    return {
      description: notes ?? description,
      location,
      productId: productId?.toString(),
      profileImageUrl: profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL,
      title,
      url,
    }
  }

  const {
    addresses,
    description,
    id: productId,
    name,
  } = likedListGuide?.guide ?? {}
  const { profileImageUrl } = likedListGuide?.likerProfile ?? {}
  const { city, state } = addresses?.[0] ?? {}
  const location = city && state ? `${city}, ${state}` : ''
  const url = `${window.location.origin}${getGuideDetailsUrl({
    id: productId,
    name,
  })}`

  return {
    description,
    location,
    productId,
    profileImageUrl: profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL,
    title,
    url,
  }
}
const getDashboardFeedTimeSincePublished = ({
  insertedAt,
  updatedAt,
  timeZone,
}: Pick<FeedItem['guide'], 'insertedAt' | 'timeZone' | 'updatedAt'>) => {
  const feedDate = getDateFromUTC(updatedAt ?? insertedAt, timeZone)
  const minutesSincePublished = Math.abs(today.diff(feedDate, 'minutes'))

  if (minutesSincePublished < 60) {
    return minutesSincePublished === 1
      ? '1 minute ago'
      : `${minutesSincePublished} minutes ago`
  }

  const hoursSincePublished = Math.abs(today.diff(feedDate, 'hours'))
  if (hoursSincePublished < 24) {
    return hoursSincePublished === 1
      ? '1 hour ago'
      : `${hoursSincePublished} hours ago`
  }

  const daysSincePublished = Math.abs(today.diff(feedDate, 'days'))
  if (daysSincePublished < 7) {
    return daysSincePublished === 1
      ? '1 day ago'
      : `${daysSincePublished} days ago`
  }

  const weeksSincePublished = Math.abs(today.diff(feedDate, 'weeks'))
  return weeksSincePublished === 1
    ? '1 week ago'
    : `${weeksSincePublished} weeks ago`
}

const getDashboardHeaderGreeting = ({
  firstName,
  isMobile,
  isProfileComplete,
}: {
  firstName: string
  isMobile: boolean
  isProfileComplete: boolean
}) => {
  if (isMobile)
    return `Hi${firstName ? ` ${firstName}` : ''}${
      isProfileComplete ? '!' : ", you're almost there!"
    }`

  return `Hello${firstName ? `, ${firstName}` : ''}`
}

export {
  getDashboardFeedGuideImages,
  getDashboardFeedItemOld,
  getDashboardFeedTimeSincePublished,
  getDashboardHeaderGreeting,
}
