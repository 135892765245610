import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  Accordion,
  AccordionItemGroup,
  AccordionPanel,
  AccordionButton,
} from 'src/common/components/Accordion'
import { TermsAndConditionsModal } from './TermsAndConditionsModal'

const AccordionSection = ({ title, children }) => {
  return (
    <AccordionItemGroup className='b-b-1 b-b-grey-300 b-b-solid children:px-4'>
      <AccordionButton className='hover:bg-black/3 text-forest cursor-pointer appearance-none py-4 text-xl transition-colors'>
        {title}
      </AccordionButton>
      <AccordionPanel>{children}</AccordionPanel>
    </AccordionItemGroup>
  )
}

export const CompetitionFaq = () => {
  const [isTnCModalOpen, setIsTnCModalOpen] = useState(false)

  return (
    <>
      <Accordion multiple={isMobile}>
        <AccordionSection title='How do I enter to win the Travelpass Dream Vacation Giveaway?'>
          <p>To officially enter the contest, you will need to:</p>
          <ol>
            <li>
              Create a profile on Travelpass.com complete with your bio, photo,
              and passion for travel (if you do not already have one).
            </li>
            <li>
              Complete at least one Guide for a destination that you know well
              and love. These guides help share experiences and recommendations
              with fellow travelers to inspire, educate and explore.
              <ol type='a'>
                <li>
                  Guides must include the following elements:
                  <ol type='i'>
                    <li>A written description of what your guide is about</li>
                    <li>
                      At least 5 “events” (hotel, activity, restaurant, point of
                      interest)
                    </li>
                    <li>
                      At least 5 personalized notes on events (your honest
                      opinion and/or recommendations about the event, location
                      or activity)
                    </li>
                    <li>
                      At least 1 personal photo attached to the guide or an
                      event
                    </li>
                  </ol>
                </li>
                <li>
                  Employees that have created guides in the past will be
                  required to add at least 1 new guide during the entry period.
                </li>
              </ol>
            </li>
            <li>
              Create a one-minute video telling us who you are and why you would
              make a great Chief Hospitality Officer, then post it on YouTube or
              Vimeo (and link it on your Travelpass profile).
            </li>
            <li>
              Share your Travelpass profile with friends, family and social
              media networks so they can vote for your candidacy.
            </li>
            <li>
              Read and agree to the Campaign’s{' '}
              <span
                aria-label='Open terms and conditions modal'
                className='cursor-pointer underline'
                role='button'
                tabIndex={0}
                onClick={() => setIsTnCModalOpen(true)}
              >
                Terms and Conditions
              </span>
            </li>
          </ol>
        </AccordionSection>
        <AccordionSection title='Who is eligible to enter the contest?'>
          <p>
            Anyone age 18+ is eligible to enter to win this contest, regardless
            of location. Contestants may enter through an employee referral link
            or directly on travelpass.com. Contest is subject to local contest
            rules and conditions.
          </p>
        </AccordionSection>
        <AccordionSection title='How do I increase my chances of winning?'>
          <ol>
            <li className='underline'>Round 1</li>
            <ol type='a'>
              <li>Points are earned by creating guides and receiving votes</li>
              <ol type='i'>
                <li>Guides</li>
                <ol>
                  <li>
                    50 points are awarded for completing your first guide that
                    meets the above criteria
                  </li>
                  <li>
                    An additional 10 points per new guide (up to 5 additional
                    guides, or 50 points) are awarded
                  </li>
                </ol>
                <li>Voting</li>
                <ol>
                  <li>
                    Points are awarded based on your ranking relative to other
                    participants
                  </li>
                  <li>The top point earner will receive 100 points</li>
                  <li>
                    2nd place will receive 99 points, and on down to the 90th
                    place entry receiving 11 points
                  </li>
                  <li>
                    Anyone not in the top 90 that receives at least 10 votes
                    will receive 10 points.
                  </li>
                </ol>
                <li>
                  Anyone can vote 1 time per 24 hour period per IP address
                  (Travelpass account required to vote)
                </li>
              </ol>
            </ol>
            <br></br>
            <li className='underline'>Round 2</li>
            <ol type='a'>
              <li>
                The 25 entries with the highest number of points will move to
                round 2
              </li>
              <li>Vote totals will be reset</li>
              <li>Entrants can again solicit votes for their profiles</li>
              <li>Only vote totals will be considered for this round</li>
              <li>
                Anyone can vote 1 time per 24 hour period per IP address
                (Travelpass account required to vote)
              </li>
            </ol>
            <br></br>
            <li className='underline'>Finals</li>
            <ol type='a'>
              <li>The top 10 vote earners will be selected for the finals</li>
              <li>
                A random drawing will be conducted to determine the winner of
                the grand prize
              </li>
              <ol type='i'>
                <li>Final drawing entries will be calculated as follows</li>
                <ol>
                  <li>Top vote earner: 10 entries</li>
                  <li>2nd place: 9 entries</li>
                  <li>3rd place: 8 entries</li>
                  <li>4th place: 7 entries</li>
                  <li>5th place: 6 entries</li>
                  <li>6th place: 5 entries</li>
                  <li>7th place: 4 entries</li>
                  <li>8th place: 3 entries</li>
                  <li>9th place: 2 entries</li>
                  <li>10th place: 1 entry</li>
                </ol>
              </ol>
            </ol>
          </ol>
        </AccordionSection>
        <AccordionSection
          title='How can I solicit votes for my profile?
'
        >
          <p>
            Voting is encouraged throughout the contest. Entrants can solicit
            votes from friends and family through word of mouth or posting on
            social media accounts. Any paid subscriptions or services used to
            increase vote count will result in disqualification.
          </p>
        </AccordionSection>
        <AccordionSection title='What are the restrictions for entering?'>
          <p>
            Applicants must be at least 18 years of age and adhere to local
            contest rules.
          </p>
        </AccordionSection>
        <AccordionSection title='How will the grand prize be delivered?'>
          <p>
            The $20,000 travel credit will be provided in a pre-paid Divvy card
            for use on any travel-related purchases. Purchases must be made as
            part of a single trip. Any unused funds will be returned to
            Travelpass.
          </p>
        </AccordionSection>
        <AccordionSection title='Do I have to upload a video of myself?'>
          <p>
            No. We required a video be linked from YouTube but it can be a video
            of yourself or of any other public travel-related content.
          </p>
        </AccordionSection>
        <AccordionSection title='How will the winner be selected from the final 10?'>
          <p>
            Once the 10 finalists are identified, each will receive 1 entrance
            into a random drawing. 1 winner will be selected at random and
            awarded the grand prize.
          </p>
        </AccordionSection>
        <AccordionSection title='Do I win if I get the most votes?'>
          <p>
            Vote count helps you advance into round 2 and the finals.
            Ultimately, the winner will be selected from the final 10 in a
            random drawing.
          </p>
        </AccordionSection>
        <AccordionSection title='Can I create more than one guide?'>
          <p>
            Creating at least 1 quality guide is a requirement for entering this
            contest. You can create an additional 5 guides to earn more points
            in round 1. We encourage everyone to create as many guides as they
            would like during this contest, but points will only be awarded for
            the first 6 quality guides.
          </p>
        </AccordionSection>
        <AccordionSection title='Why did you decide to run this contest?'>
          <p>
            Our goal is to make Travelpass the largest and best platform for
            discovery, planning, and travel booking. So many travelers have
            great ideas, recommendations and memories from their trips. Our
            platform allows you to not only record the activities you loved
            during your trip, but share the information with others. Building an
            authentic, unbiased community for travelers to share their guides
            will inspire others to see more of the world.
          </p>
          <p>
            We want to ensure the process for creating guides is easy and
            intuitive for new users. This contest will help us add more guides
            to the Travelpass community and gather valuable feedback on the
            guide creation process.
          </p>
          <p>
            We will also be launching a campaign in 2025 to hire a content
            creator to travel the world, creating guides for new destinations.
            This contest will help us test the platform for that job search
            campaign.
          </p>
        </AccordionSection>
        <AccordionSection title="What's the timeline for all of this? When will you announce the finalists? The winner?">
          <p>Here&apos;s what each stage looks like in a nutshell:</p>
          <ul className='list-none'>
            <li>
              <strong>Oct 16: </strong>Giveaway launch, entries open, voting
              begins
            </li>
            <li>
              <strong>Nov 1: </strong>
              Deadline for entering giveaway
            </li>
            <li>
              <strong>Nov 8: </strong>
              Deadline for round 1 voting
            </li>
            <li>
              <strong>Nov 11: </strong>
              Top 25 announced, round 2 voting opens
            </li>
            <li>
              <strong>Nov 21: </strong>
              Round 2 voting ends
            </li>
            <li>
              <strong>Nov 22: </strong>
              Top 10 finalists entered to drawing, winner announced
            </li>
          </ul>
          <p>
            For a more detailed timeline, check out our{' '}
            <span
              aria-label='Open terms and conditions modal'
              className='cursor-pointer underline'
              role='button'
              tabIndex={0}
              onClick={() => setIsTnCModalOpen(true)}
            >
              Terms and Conditions.
            </span>
          </p>
        </AccordionSection>
        <AccordionSection title='How and when will the winner be notified?'>
          <p>
            The winner will be notified by email or phone on November 22, 2024.
          </p>
        </AccordionSection>
        <AccordionSection title='Do I have to pay taxes on the prize?'>
          <p>TBD</p>
        </AccordionSection>
        <AccordionSection title='How do I know this is for real?'>
          <p>
            Feel free to check out our website, social media accounts and
            LinkedIn Pages to learn more about Travelpass:
          </p>
          <ul>
            <li>
              <a href='/'>Travelpass.com</a>
            </li>
            <li>Travelpass Instagram</li>
            <li>Travelpass TikTok</li>
            <li>Travelpass Group LinkedIn</li>
          </ul>
        </AccordionSection>
      </Accordion>
      {isTnCModalOpen && (
        <TermsAndConditionsModal
          controlledByParent={true}
          onClose={() => setIsTnCModalOpen(false)}
        />
      )}
    </>
  )
}
