import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Input, ModalNext } from '@travelpass/design-system'
import { useForm } from 'react-hook-form'
import { gql } from 'src/__generated__'
import {
  CollectionItemIdType,
  type GetAllGuideDraftsQueryVariables,
  GuideDraftStatus,
} from 'src/__generated__/graphql'
import { useGetCurrentUserId } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { getGuideDraftsForUserQuery } from 'src/pages/dashboard'
import { SignInModal } from 'src/pages/signin'
import { AddToGuidesList } from './AddToGuidesList'
import { dispatchToggleAddToGuideModal } from './dispatchToggleAddToGuideModal'
import { useGetAllGuides } from './useGetAllGuides'

const CREATE_GUIDE_DRAFT = gql(`
  mutation CreateGuideDraft ($createGuideDraftInput: CreateGuideDraftInput!) {
    createGuideDraft(input: $createGuideDraftInput) {
      guideDraft {
        id
        name
      }
    }  
  }
`)

interface AddToGuideModalProps {
  onClose(): void
  /** ID and Name from a hotel, experience, guide or event. */
  item: AddToItem
  onCompleted?: (isCollected: boolean) => void
}

interface GuideFormFields {
  name: string
}

export const AddToGuideModal = ({ onClose, item }: AddToGuideModalProps) => {
  const { isAnonymous } = useFirebaseUser()
  const [showNewGuideForm, setShowNewGuideForm] = useState(false)

  const { data: currentUserData } = useGetCurrentUserId()
  const userId = currentUserData?.currentUser?.id ?? ''

  const variables: GetAllGuideDraftsQueryVariables = {
    userGuideDraftsArgs: {
      includeStatuses: [GuideDraftStatus.Active],
    },
    includeExperience: item.type === CollectionItemIdType.Experience,
    includeHotel: item.type === CollectionItemIdType.Hotel,
    includeEvent: item.type === CollectionItemIdType.Event,
    productId: item.type === CollectionItemIdType.Experience ? item.id : null,
    hotelId: item.type === CollectionItemIdType.Hotel ? item.id : null,
    publishedEventId: item.type === CollectionItemIdType.Event ? item.id : null,
  }

  const { data, loading, error, refetch } = useGetAllGuides(variables)
  const [createGuideDraft] = useMutation(CREATE_GUIDE_DRAFT)
  const defaultValues: GuideFormFields = { name: '' }
  const methods = useForm({ defaultValues })
  const errorMessage = methods?.formState?.errors?.name?.message

  // the refetch query is needed so that user's dashboard will show the newly created guide
  const generateGuidesRefetch = () => {
    return { query: getGuideDraftsForUserQuery, variables: { userId } }
  }

  const onSubmit = async ({ name }: GuideFormFields) => {
    try {
      if (!name) {
        return
      }
      await createGuideDraft({
        refetchQueries: [generateGuidesRefetch()],
        variables: { createGuideDraftInput: { name } },
      })
      refetch()
      setShowNewGuideForm(false)
      methods?.setValue('name', '')
    } catch (error) {
      console.error('Failed to create guide', error)
    }
  }

  return (
    <>
      {!isAnonymous ? (
        <ModalNext
          actions={
            <div
              className={`mt-8 flex w-full flex-row-reverse gap-3 ${showNewGuideForm ? 'md:[&_button]:w-38 [&_button]:w-25 justify-center md:gap-8' : '[&_button]:max-w-54 [&_button]:w-54 md:[&_button]:max-w-unset md:[&_button]:w-unset items-center justify-between max-md:flex-col-reverse'}`}
            >
              <Button
                form={showNewGuideForm ? 'create-guide-form' : null}
                label={showNewGuideForm ? 'Save' : 'Done'}
                type={showNewGuideForm ? 'submit' : 'button'}
                onClick={() => {
                  !showNewGuideForm && onClose()
                }}
              />
              <Button
                label={showNewGuideForm ? 'Cancel' : 'Create new Guide'}
                variant='outlined'
                onClick={() => setShowNewGuideForm(!showNewGuideForm)}
              />
            </div>
          }
          className='max-md:[&>div]:w-full'
          scroll={true}
          size='small'
          onClose={onClose}
        >
          <div className='px-2'>
            <h2 className='type-h4 mb-2'>Add to a Guide</h2>
            {!showNewGuideForm && (
              <p className='type-body-2 c-gray-900 mb-6'>
                Select one or multiple Guides from the list to add to.
              </p>
            )}
            {showNewGuideForm && (
              <form
                className='my-5 flex'
                id='create-guide-form'
                onSubmit={methods?.handleSubmit(onSubmit)}
              >
                <Input
                  autoFocus
                  fullWidth
                  aria-label='Name guide'
                  errorText={errorMessage}
                  name='guideName'
                  placeholder='Name guide'
                  {...methods?.register('name', {
                    maxLength: {
                      value: 50,
                      message: 'Guide name must be 50 or fewer characters',
                    },
                    required: 'Guide name is required',
                    onBlur: () => methods.clearErrors(),
                    validate: value => {
                      return value.length <= 50
                    },
                  })}
                />
              </form>
            )}
            {error ? (
              <p className='type-body-1 mt-10'>Error while loading Guides...</p>
            ) : (
              <AddToGuidesList
                guides={data}
                guidesLoading={loading}
                item={item}
                refetch={refetch}
              />
            )}
          </div>
        </ModalNext>
      ) : (
        <SignInModal
          initiallyShowCreateAccount={false}
          onClose={() =>
            dispatchToggleAddToGuideModal({ isModalOpen: false, item: null })
          }
        />
      )}
    </>
  )
}
