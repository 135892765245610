import { Divider } from '@travelpass/design-system'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { useFlag } from 'src/common/hooks'
import type { FollowersModalType } from 'src/pages/profile/types'
import {
  DashboardFeedCardCreatedGuide,
  DashboardFeedCardRepublishedGuideCreatedEvent,
  DashboardFeedCardRepublishedGuideCreatedNote,
  DashboardFeedCardRepublishedGuideGrouped,
  DashboardFeedCardRepublishedGuidePhotos,
} from './DashboardFeedCard'
import { DashboardFeedEmptyState } from './DashboardFeedEmptyState'
import { DashboardFeedLoading } from './DashboardFeedLoading'
import { DashboardFeedSuggestedFollowers } from './DashboardFeedSuggestedFollowers'
import { DashboardFeedSuggestedGuides } from './DashboardFeedSuggestedGuides'
import { DashboardFeedSuggestedTravelpassGuides } from './DashboardFeedSuggestedTravelpassGuides'
import { useGetDashboardFeedQuery } from '../../useGetDashboardFeedQuery'

interface DashboardFeedProps {
  toggleFollowersModal: (modalType: FollowersModalType) => void
}

export const DashboardFeed = ({ toggleFollowersModal }: DashboardFeedProps) => {
  const { dashboardFeedGuideData, isLoading } = useGetDashboardFeedQuery()
  const isDashboardV3FeedNewEventEnabled = useFlag('dashboardV3FeedNewEvent')
  const isDashboardV3FeedNewGuideEnabled = useFlag('dashboardV3FeedNewGuide')
  const isDashboardV3FeedNewNoteEnabled = useFlag('dashboardV3FeedNewNote')
  const isDashboardV3FeedNewPhotosEnabled = useFlag('dashboardV3FeedNewPhotos')
  const isDashboardV3FeedRepublishGuideGroupedEnabled = useFlag(
    'dashboardV3FeedRepublishGuideGrouped'
  )
  const isDashboardV3FeedSuggestedFollowersEnabled = useFlag(
    'dashboardV3FeedSuggestedFollowers'
  )
  const isDashboardV3FeedSuggestedGuidesEnabled = useFlag(
    'dashboardV3FeedSuggestedGuides'
  )
  const isDashboardV3FeedTravelpassGuidesEnabled = useFlag(
    'dashboardV3FeedTravelpassGuides'
  )

  if (isLoading) return <DashboardFeedLoading />

  if (
    isDashboardV3FeedSuggestedFollowersEnabled &&
    !dashboardFeedGuideData?.length
  )
    return (
      <div className='space-y-12'>
        <DashboardFeedSuggestedFollowers />
        <DashboardFeedEmptyState toggleFollowersModal={toggleFollowersModal} />
      </div>
    )

  return (
    <>
      <div className='space-y-4'>
        {isDashboardV3FeedNewGuideEnabled && (
          <div className='space-y-4'>
            {dashboardFeedGuideData?.map((dashboardFeedItem, index) => (
              <DashboardFeedCardCreatedGuide
                key={index}
                dashboardFeedItem={dashboardFeedItem}
              />
            ))}
          </div>
        )}
        {/** @todo remove when BE adds feed type */}
        {isDashboardV3FeedNewEventEnabled && (
          <div>
            <h3 className='m-t-10 type-h1'>New Event</h3>
            <Divider className='m-b-6 m-t-3' />
            <div className='space-y-4'>
              <DashboardFeedCardRepublishedGuideCreatedEvent
                dashboardFeedItem={dashboardFeedGuideData?.[0]}
              />
            </div>
          </div>
        )}
        {isDashboardV3FeedNewNoteEnabled && (
          <div>
            <h3 className='m-t-10 type-h1'>New Note</h3>
            <Divider className='m-b-6 m-t-3' />
            <div className='space-y-4'>
              <DashboardFeedCardRepublishedGuideCreatedNote
                dashboardFeedItem={dashboardFeedGuideData?.[0]}
              />
            </div>
          </div>
        )}
        {isDashboardV3FeedNewPhotosEnabled && (
          <div>
            <h3 className='m-t-10 type-h1'>Photos Added to a Guide</h3>
            <Divider className='m-b-6 m-t-3' />
            <div className='space-y-4'>
              {dashboardFeedGuideData?.map((dashboardFeedItem, index) => (
                <DashboardFeedCardRepublishedGuidePhotos
                  key={index}
                  dashboardFeedItem={dashboardFeedItem}
                />
              ))}
            </div>
          </div>
        )}
        {isDashboardV3FeedRepublishGuideGroupedEnabled && (
          <div>
            <h3 className='m-t-10 type-h1'>Updated a Guide</h3>
            <Divider className='m-b-6 m-t-3' />
            <div className='space-y-4'>
              {dashboardFeedGuideData?.map((dashboardFeedItem, index) => (
                <DashboardFeedCardRepublishedGuideGrouped
                  key={index}
                  dashboardFeedItem={dashboardFeedItem}
                />
              ))}
            </div>
          </div>
        )}
        {isDashboardV3FeedSuggestedFollowersEnabled && (
          <DashboardFeedSuggestedFollowers />
        )}
        {isDashboardV3FeedSuggestedGuidesEnabled && (
          <DashboardFeedSuggestedGuides />
        )}
        {isDashboardV3FeedTravelpassGuidesEnabled && (
          <DashboardFeedSuggestedTravelpassGuides />
        )}
      </div>
      <AddToCollectionsModalListener />
    </>
  )
}
