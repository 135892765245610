import { Button, Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import type { GuideSorts } from 'src/__generated__/graphql'
import {
  GuideRecommendedCard,
  GuideRecommendedCardLoading,
  PageLayoutContainer,
  useOnClickOwnerProfile,
} from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { useGetPredefinedGuideTagsQuery } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { ProfileModalListener } from 'src/pages/guides/results/ProfileModalListener'
import { getTracker } from 'src/utils'
import { ExploreHero } from '../components/ExploreHero'
import { ExploreZeroState } from '../components/ExploreZeroState'
import { FiltersAndSortContainer } from '../components/FiltersAndSortContainer'
import { EXPLORE_SORT_SP } from '../constants'
import { useGetGuides } from '../hooks/useGetGuides'

// magic number to fill the page
const N_GUIDES = 20

const resultsGridClassNames =
  'grid grid-cols-1 gap-2 py-6 md:grid-cols-2 md:gap-5 lg:grid-cols-3'

const LoadingGuides = () => (
  <div
    className={classNames(
      resultsGridClassNames,
      'max-md:[&_div:nth-child(3)_~_div]:hidden',
      'max-lg:[&_div:nth-child(4)_~_div]:hidden',
      'children:w-full!'
    )}
  >
    <GuideRecommendedCardLoading />
    <GuideRecommendedCardLoading />
    <GuideRecommendedCardLoading />
    <GuideRecommendedCardLoading />
    <GuideRecommendedCardLoading />
    <GuideRecommendedCardLoading />
  </div>
)

export const TagSearch = () => {
  const [searchParams] = useSearchParams()
  const onClickOwnerProfile = useOnClickOwnerProfile()

  const sort = searchParams.get(EXPLORE_SORT_SP)
  const title = searchParams.get('title')
  const accountHandles = searchParams.get('createdBy')?.split(',')
  const tags = searchParams.get('tags')?.split(',')

  const skip = !tags?.length

  const {
    data,
    fetchMore,
    loading: guidesLoading,
  } = useGetGuides({
    variables: {
      first: N_GUIDES + 1,
      guideSearchArgs: {
        tracker: getTracker(tags?.join(',') + '-general'),
        sortBy: sort as GuideSorts,
        filterBy: {
          accountHandles,
          tagIds: tags,
        },
      },
    },
    skip,
  })

  const guides = data?.guideSearch?.edges
  const guidesCount = data?.guideSearch?.totalCount
  const cursor =
    data?.guideSearch?.pageInfo?.hasNextPage &&
    data.guideSearch?.pageInfo.endCursor

  const anyIsLoading = guidesLoading
  const totalCount = guidesCount || 0
  const showEmptyState = !anyIsLoading && totalCount <= 0

  const { tags: initialTags } = useGetPredefinedGuideTagsQuery(
    true,
    tags?.length == 0
  )

  const semanticTagList = tags?.map((tag, i) =>
    i === tags?.length - 1
      ? `and ${initialTags?.find(t => t?.id === tag)?.name}`
      : initialTags?.find(t => t?.id === tag)?.name
  )

  const formattedTagList =
    semanticTagList?.length > 2
      ? semanticTagList?.join(', ')
      : semanticTagList?.join(' ')

  const labelTagList =
    tags?.length > 1
      ? formattedTagList
      : initialTags?.find(t => t?.id === tags?.[0])?.name

  const complementaryLabel = !!tags?.length && `for ${labelTagList}`

  return (
    <div className='mb-8 space-y-8'>
      <ExploreHero />
      <PageLayoutContainer>
        <div className='space-y-12'>
          <FiltersAndSortContainer
            searchedTerm={title}
            totalCount={totalCount}
          />
          {showEmptyState && <ExploreZeroState />}
          {!!guides?.length && (
            <section>
              <header>
                <h2 className='type-h3 color-grey-900 [&_i]:color-valley flex flex-row items-center gap-2 capitalize'>
                  <Icon name='navigationCompass' size='medium' />
                  Guides {complementaryLabel}{' '}
                  {!!guidesCount && `(${guidesCount})`}
                </h2>
              </header>
              <div className={resultsGridClassNames}>
                {guides?.map(({ node: guide }, index) => (
                  <GuideRecommendedCard
                    key={`guide-card-${index}`}
                    hideAddTo
                    guideRecommendedCardData={guide}
                    onClickOwnerProfile={onClickOwnerProfile}
                  />
                ))}
              </div>
              {cursor && (
                <div className='mx-auto grid w-full place-items-center py-6'>
                  <Button
                    aria-label='Load more guides'
                    disabled={guidesLoading}
                    size='small'
                    variant='outlined'
                    onClick={async () => {
                      await fetchMore({
                        variables: {
                          after: cursor,
                        },
                      })
                      pushDataToDataLayer('guide_search_load_more_click')
                    }}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </section>
          )}
          {guidesLoading && <LoadingGuides />}
        </div>
      </PageLayoutContainer>

      <AddToCollectionsModalListener />
      <ProfileModalListener />
    </div>
  )
}
