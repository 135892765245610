import { DashboardFeedCardOld } from './DashboardFeedCardOld'
import { DashboardFeedLoadingOld } from './DashboardFeedLoadingOld'
import { useGetDashboardFeedQueryOld } from '../../useGetDashboardFeedQueryOld'

export const DashboardFeedOld = () => {
  const { dashboardFeedData, isLoading } = useGetDashboardFeedQueryOld()

  if (isLoading || !dashboardFeedData?.length)
    return <DashboardFeedLoadingOld />

  return (
    !!dashboardFeedData?.length && (
      <section className='space-y-6'>
        <h3 className='c-black type-h3-mobile type-h4-desktop'>Following</h3>
        <div className='space-y-6'>
          {dashboardFeedData?.map((dashboardFeedItem, index) => (
            /** @todo replace index with ID when returned on the BE */
            <DashboardFeedCardOld
              key={index}
              dashboardFeedItem={dashboardFeedItem}
            />
          ))}
        </div>
      </section>
    )
  )
}
