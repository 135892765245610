import { useState } from 'react'
import { Button, IconButton, useSnackbar } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useParams, useSearchParams } from 'react-router-dom'
import { CollectionItemIdType } from 'src/__generated__/graphql'
import { dispatchToggleAddToCollectionsModal } from 'src/common/components/Collections/dispatchToggleAddToCollectionsModal'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import {
  getCurrentUserLikedGuidesQuery,
  useGetUserLikesGuideQuery,
} from 'src/pages/guides/useGetUserLikesGuideQuery'
import { useIncrementGuideShareCountMutation } from 'src/pages/guides/useIncrementGuideShareCountMutation'
import { useLikeGuideMutation } from 'src/pages/guides/useLikeGuideMutation'
import { SignInModal } from 'src/pages/signin'
import { encodeGuideId } from 'src/utils'
import { useShareGuide } from '../..'
import { GuideSearchParam } from '../guideConstants'

interface GuideStickyActionButtonsProps {
  isUserOwner: boolean
  guideName: string
}

export const GuideStickyActionButtons = ({
  isUserOwner,
  guideName,
}: GuideStickyActionButtonsProps) => {
  const isGuideUrlShortEnabled = useFlag('guideUrlShort')
  const { guideId } = useParams()
  const { isAnonymous } = useFirebaseUser()
  const { hasLikedGuide } = useGetUserLikesGuideQuery(guideId)
  const shareGuide = useShareGuide()
  const [incrementShareCount] = useIncrementGuideShareCountMutation()
  const [likeGuide] = useLikeGuideMutation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const [hasShared, setHasShared] = useState(false)
  const [showSignModal, setShowSignModal] = useState(false)
  const guideIdEncoded = encodeGuideId({
    guideId,
    isGuideDraft: false,
    isGuideUrlShortEnabled,
  })

  const onLikeGuide = async () => {
    if (isAnonymous) {
      setShowSignModal(true)
    } else {
      try {
        likeGuide({
          variables: {
            guideId: guideIdEncoded,
          },
          refetchQueries: [getCurrentUserLikedGuidesQuery],
        })
      } catch (error) {
        addErrorSnack({ timeout: 1000, title: 'Already liked!' })
      }
    }
  }

  const onMapClick = () => {
    searchParams.set(GuideSearchParam.mapExpanded, 'true')
    setSearchParams(searchParams, { replace: true })
  }

  const onSaveGuide = () => {
    dispatchToggleAddToCollectionsModal({
      isModalOpen: true,
      item: {
        id: isGuideUrlShortEnabled ? guideIdEncoded : guideId,
        name: guideName,
        type: CollectionItemIdType.Guide,
      },
    })
  }

  const onShareGuide = async () => {
    await shareGuide({
      guideDraftTitle: name,
      isUserOwner,
      shareUrl: window.location.href,
      onSuccessfulShare: () => {
        addSuccessSnack({ timeout: 1000, title: 'Link copied' })
      },
    })
    if (!hasShared) {
      incrementShareCount({
        variables: {
          guideId: isGuideUrlShortEnabled ? guideIdEncoded : guideId,
        },
      })
      setHasShared(true)
    }
  }

  return (
    <>
      <div className='flex w-full flex-row items-center justify-between gap-3'>
        <Button size='small' startIcon='bookmarkBorder' onClick={onSaveGuide}>
          Save
        </Button>
        <div className='flex w-full flex-row items-center justify-end gap-3'>
          <IconButton
            aria-label='Like this guide'
            color='forestLight'
            icon={hasLikedGuide ? 'favorite' : 'favoriteBlank'}
            outline='round'
            size='small'
            onClick={debounce(onLikeGuide, 100)}
          />
          <IconButton
            aria-label='Share this guide'
            color='forestLight'
            icon='iosShare'
            outline='round'
            size='small'
            onClick={onShareGuide}
          />
          <IconButton
            aria-label='Click to open map'
            color='forestLight'
            icon='mapOutlined'
            outline='round'
            size='small'
            onClick={onMapClick}
          />
        </div>
      </div>
      {showSignModal && <SignInModal onClose={() => setShowSignModal(false)} />}
    </>
  )
}
