import type { ApolloError } from '@apollo/client'
import { dashboardFeedSuggestedGuideMock } from './__tests__/__mocks__'
import type { DashboardFeedSuggestedGuideItem } from './types'

type UseGetDashboardFeedSuggestedGuidesQuery = {
  dashboardFeedSuggestedGuideData: DashboardFeedSuggestedGuideItem[]
  hasError: ApolloError
  isLoading: boolean
}

const useGetDashboardFeedSuggestedGuidesQuery =
  (): UseGetDashboardFeedSuggestedGuidesQuery => {
    return {
      /** @todo replace mocked data (waiting on BE) */
      dashboardFeedSuggestedGuideData: dashboardFeedSuggestedGuideMock,
      hasError: undefined,
      isLoading: false,
    }
  }

export type { UseGetDashboardFeedSuggestedGuidesQuery }
export { useGetDashboardFeedSuggestedGuidesQuery }
