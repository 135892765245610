import { useEffect, useState } from 'react'
import type { UpdateGuideDraftInput } from 'src/__generated__/graphql'
import { useFlag, useGetPresignedUrlsLazyQuery } from 'src/common/hooks'
import { initialGuideDraftPublishInvalidFormValues } from './guideDraftPublishConstants'
import {
  clearInvalidFormValues,
  getGuideDraftPublishCategorizedImages,
  getGuideDraftPublishInitialFormValues,
  processGuideDraftPublishImageUploads,
  processGuideDraftPublishNextStep,
} from './guideDraftPublishUtils'
import type {
  GuideDraftPublishFormValues,
  GuideDraftPublishInvalidFormValues,
  GuideDraftPublishProcessedImage,
  UseGuideDraftPublishForm,
} from './types'
import { useAddGuideDraftImagesMutation } from '../../../useAddGuideDraftImagesMutation'
import { useCreateGuideFromGuideDraftMutation } from '../../../useCreateGuideFromGuideDraftMutation'
import { useRemoveGuideDraftImagesMutation } from '../../../useRemoveGuideDraftImagesMutation'
import { useUpdateGuideMutation } from '../../../useUpdateGuideMutation'
import type { GuideDraftData } from '../../types'
import { getGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { useUpdateGuideDraftMutation } from '../../useUpdateGuideDraftMutation'

export const useGuideDraftPublishForm = (
  guideDraftData?: GuideDraftData
): UseGuideDraftPublishForm => {
  const [addGuideDraftImages] = useAddGuideDraftImagesMutation()
  const [createGuideFromGuideDraft, createGuideFromGuideDraftResult] =
    useCreateGuideFromGuideDraftMutation()
  const [getPresignedUrls] = useGetPresignedUrlsLazyQuery()
  const [removeGuideDraftImages] = useRemoveGuideDraftImagesMutation()
  const [updateGuide, updateGuideResult] = useUpdateGuideMutation()
  // `false` is passed as the second argument to `useUpdateGuideDraftMutation` to disable the `hasUnpublishedChanges` check
  const [updateGuideDraft, updateGuideDraftResult] =
    useUpdateGuideDraftMutation(false)
  const [invalidFormValues, setInvalidFormValues] =
    useState<GuideDraftPublishInvalidFormValues>(
      initialGuideDraftPublishInvalidFormValues
    )
  const [isPresignedUploading, setIsPresignedUploading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [processedImageUploads, setProcessedImageUploads] = useState<
    GuideDraftPublishProcessedImage[]
  >([])
  const [skipPresignedUpload, setSkipPresignedUpload] = useState(false)
  const [step, setStep] = useState(0)
  const { id: guideDraftId, images } = guideDraftData ?? {}
  const [formValues, setFormValues] = useState<GuideDraftPublishFormValues>(
    () => getGuideDraftPublishInitialFormValues(guideDraftData)
  )
  const isLoading =
    createGuideFromGuideDraftResult?.loading ||
    isPresignedUploading ||
    isUploading ||
    updateGuideDraftResult?.loading ||
    updateGuideResult?.loading

  useEffect(() => {
    if (formValues?.images?.length) {
      setSkipPresignedUpload(false)
    }
  }, [formValues?.images])

  const onFormValuesChange = (values: GuideDraftPublishFormValues) => {
    const updatedInvalidFormValues = clearInvalidFormValues({
      formValues: values,
      invalidFormValues,
    })
    setFormValues(values)
    onInvalidFormValuesChange(updatedInvalidFormValues)
  }

  const onInvalidFormValuesChange = (
    updatedInvalidFormValues: GuideDraftPublishInvalidFormValues
  ) => setInvalidFormValues(updatedInvalidFormValues)

  const onNextStep = async () => {
    await processGuideDraftPublishNextStep({
      formValues,
      invalidFormValues,
      onInvalidFormValuesChange,
      onPresignedUpload,
      onSubmit,
      onStepChange,
      skipPresignedUpload,
      step,
    })
  }

  const onPresignedUpload = async (callback = () => {}) => {
    const updatedInvalidFormValues = {
      ...invalidFormValues,
    }
    setIsPresignedUploading(true)
    const { createdImages } = getGuideDraftPublishCategorizedImages({
      currentImages: images,
      formValues,
    })

    try {
      const updatedProcessedImageUploads =
        await processGuideDraftPublishImageUploads({
          createdImages,
          getPresignedUrls,
        })
      setProcessedImageUploads(updatedProcessedImageUploads)
      updatedInvalidFormValues.imagesUploaded =
        createdImages.length !== updatedProcessedImageUploads.length
      setInvalidFormValues(updatedInvalidFormValues)

      if (!updatedInvalidFormValues.imagesUploaded) return callback?.()
    } catch (error) {
      console.error(error)
    } finally {
      setSkipPresignedUpload(true)
      setIsPresignedUploading(false)
    }

    return updatedInvalidFormValues
  }

  const onPreviousStep = () => setStep(previousStep => previousStep - 1)

  const onStepChange = (updatedStep: number) => setStep(updatedStep)

  const onSubmit = async (callback = () => {}) => {
    if (isLoading) return

    try {
      const imageSortOrder = await onUpload()
      await updateGuideDraft({
        variables: {
          input: {
            addresses: [formValues.address],
            description: formValues.description,
            guideDraftId,
            guideTagIds: formValues.tagIds,
            imageSortOrder,
            name: formValues.name,
          },
        },
      })
      const createGuideFromGuideDraftResponse = await createGuideFromGuideDraft(
        {
          variables: {
            input: {
              guideDraftId,
            },
          },
        }
      )
      const id =
        createGuideFromGuideDraftResponse?.data?.createGuideFromGuideDraft
          ?.guide?.id
      await updateGuide({
        variables: {
          updateGuideInput: {
            guideId: id,
            status: formValues.status,
          },
        },
        refetchQueries: [
          {
            query: getGuideDraftQuery,
            variables: {
              id: guideDraftId ?? '',
            },
          },
        ],
      })
      callback?.()
    } catch (error) {
      console.error(error)
    }
  }

  const onUpload = async (): Promise<
    UpdateGuideDraftInput['imageSortOrder']
  > => {
    setIsUploading(true)
    const updatedImageSortOrder: UpdateGuideDraftInput['imageSortOrder'] = []
    const { deletedImageIds, unchangedImageIds } =
      getGuideDraftPublishCategorizedImages({
        currentImages: images,
        formValues,
      })

    try {
      if (deletedImageIds?.length) {
        await removeGuideDraftImages({
          variables: {
            input: {
              guideDraftId,
              imageIds: deletedImageIds,
            },
          },
        })
      }

      if (processedImageUploads.length) {
        const addGuideDraftImagesResponses = []

        for (const { source, url } of processedImageUploads) {
          try {
            const response = await addGuideDraftImages({
              variables: {
                input: {
                  guideDraftId,
                  source,
                  urls: [url],
                },
              },
            })
            addGuideDraftImagesResponses.push(response)
          } catch (error) {
            console.error(error)
          }
        }
        const filteredAddGuideDraftImageIds =
          addGuideDraftImagesResponses.reduce((total, current) => {
            const { id } =
              current?.data?.addGuideDraftImages?.addedImages[0] ?? {}

            if (id) total.push(id)

            return total
          }, [])
        updatedImageSortOrder.push(...filteredAddGuideDraftImageIds)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsUploading(false)
    }

    return unchangedImageIds.concat(updatedImageSortOrder)
  }

  return {
    formValues,
    invalidFormValues,
    isLoading,
    isPresignedUploading,
    onFormValuesChange,
    onInvalidFormValuesChange,
    onNextStep,
    onPreviousStep,
    onSubmit,
    onStepChange,
    step,
  }
}
