import { Icon, Link } from '@travelpass/design-system'
import {
  useLucencyNumber,
  onPhoneNumberClick,
} from 'src/config/analytics/lucencyUtils'
import { type ExperienceEvent } from '../constants'

export const ExperienceBottom = ({
  data,
}: {
  data: ExperienceEvent | null
}) => {
  const { lucencyNumber } = useLucencyNumber()
  const lucencyPhoneNumber = lucencyNumber()
  const browserPhone = data?.phone?.replace(/[^0-9]/g, '')

  return (
    <div className='space-y-8 px-8 py-8'>
      {data?.phone && (
        <div>
          <h2 className='type-h6'>Contact Tour Provider</h2>
          <p className='type-body-2 pt-2'>
            For questions about your meeting point, times, or other tour details
            please contact your tour provider directly.
          </p>
          <div className='c-newForest flex flex-row items-start justify-start gap-2 pt-4'>
            <Icon name='phone' size='small' />
            <Link
              className='line-clamp-1 text-nowrap'
              draggable='false'
              href={`tel:${browserPhone}`}
            >
              {browserPhone ?? ''}
            </Link>
          </div>
        </div>
      )}

      <div>
        <h2 className='type-h6'>Contact Travelpass Group</h2>
        <p className='type-body-2 pt-2'>
          For any reservation question, contact Travelpass directly or check out
          our FAQ&apos;s.
        </p>
        <div className='c-newForest flex flex-row items-start justify-start gap-2 pt-4'>
          <Icon name='phone' size='small' />
          <Link
            className='line-clamp-1 text-nowrap'
            draggable='false'
            href={`tel:${lucencyPhoneNumber}`}
            rel='noopener'
            onClick={() => onPhoneNumberClick(lucencyPhoneNumber)}
          >
            {lucencyPhoneNumber}
          </Link>
        </div>
      </div>
    </div>
  )
}
