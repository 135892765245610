import type { QueryHookOptions } from '@apollo/client'
import { useLazyQuery, useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  GuideSearchQuery,
  GuideSearchQueryVariables,
} from 'src/__generated__/graphql'

export const GET_GUIDES_QUERY = gql(`
    query getGuides($guideSearchArgs: GuideSearchArgs!, $first: Int, $after: String) {
        guideSearch(guideSearchArgs: $guideSearchArgs, first: $first, after: $after) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                id
                addresses {
                    id
                    city
                    country
                    state
                }
                isCollected
                endDate
                guideType
                images {
                    id
                    source
                    type
                    url
                }
                imageUrl
                insertedAt
                name
                ownerProfile {
                    id
                    accountHandle
                    displayName
                    profileImageUrl
                }
                startDate
                updatedAt
                timeZone
                }
            }
        }
    }
`)

export const useGetGuides = (
  options?: QueryHookOptions<GuideSearchQuery, GuideSearchQueryVariables>
) => useQuery(GET_GUIDES_QUERY, options)

export const useGetLazyGuides = (
  options?: QueryHookOptions<GuideSearchQuery, GuideSearchQueryVariables>
) => useLazyQuery(GET_GUIDES_QUERY, options)
