import { useState } from 'react'
import { Divider } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import { SearchPreferencesQuiz } from 'src/pages/account-settings/components/search-preferences-quiz/SearchPreferencesQuiz'
import { GuideDraftCreateModal } from 'src/pages/guides/lists/common'
import { FollowersModal } from 'src/pages/profile/components/FollowersModal/FollowersModal'
import type { FollowersModalType } from 'src/pages/profile/types'
import { DashboardCta } from './DashboardCta'
import { DashboardCtaQuiz } from './DashboardCtaQuiz'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'

interface DashboardCtasProps {
  dashboardData: UseGetDashboardQuery['dashboardData']
  toggleFollowersModal: (modalType: FollowersModalType) => void
}

export const DashboardCtas = ({
  dashboardData,
  toggleFollowersModal,
}: DashboardCtasProps) => {
  const enablePlanPage = useFlag('enablePlanPage')
  const isSearchPreferencesQuizEnabled = useFlag('searchPreferencesQuizEnabled')
  const navigate = useNavigate()
  const [showModalCreateGuideDraft, setShowModalCreateGuideDraft] =
    useState(false)
  const [showModalQuiz, setShowModalQuiz] = useState(false)
  const { searchPreferences, userProfile } = dashboardData?.currentUser ?? {}
  const hasUserTakenQuiz = !!searchPreferences?.id
  const pathSegment = enablePlanPage ? 'book' : 'search'

  return (
    <>
      <section className='pt-18 space-y-12 px-4 pb-12'>
        <div className='pb-21 space-y-8'>
          <DashboardCta
            buttonText='Share Your Tips'
            description='Share recommendations about your favorite destinations.'
            illustration='world'
            title='Create A Travel Guide'
            onClick={() => setShowModalCreateGuideDraft(true)}
          />
          <Divider />
          <DashboardCta
            buttonText='Find Travelers'
            description='Connect with friends and other travelers for endless trip inspo.'
            illustration='scooter'
            title='Follow Travelers'
            onClick={() => toggleFollowersModal('explore')}
          />
          <Divider />
          <DashboardCta
            buttonText='Search'
            description='Book recommended hotels and experiences for your upcoming adventure.'
            illustration='suitcase'
            title='Book Your Trip'
            onClick={() => navigate(`/${pathSegment}`)}
          />
        </div>
        {isSearchPreferencesQuizEnabled && (
          <DashboardCtaQuiz
            hasUserTakenQuiz={hasUserTakenQuiz}
            onShowModalQuiz={() => setShowModalQuiz(true)}
          />
        )}
      </section>
      {isSearchPreferencesQuizEnabled && showModalQuiz && (
        <SearchPreferencesQuiz
          useModal={true}
          onClose={() => setShowModalQuiz(false)}
        />
      )}
      {showModalCreateGuideDraft && (
        <GuideDraftCreateModal
          onDismiss={() => setShowModalCreateGuideDraft(false)}
        />
      )}
    </>
  )
}
