import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { MobileBottomNav } from 'src/common/components/MobileBottomNav'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import {
  DashboardAchievements,
  DashboardBookings,
  DashboardCollections,
  DashboardCtas,
  DashboardFeed,
  DashboardFeedOld,
  DashboardGuideDrafts,
  DashboardHeader,
  DashboardLoading,
  DashboardNav,
  DashboardProfile,
  DashboardProfileAvatarModal,
  DashboardProfileBannerModal,
  DashboardProfileInfoModal,
  DashboardTrips,
} from './common'
import { DashboardAccountSettings } from './common/DashboardAccountSettings'
import { DashboardSearchParam, DashboardTab } from './dashboardConstants'
import { useGetDashboardQuery } from './useGetDashboardQuery'
import { FollowersModal } from '../profile/components/FollowersModal/FollowersModal'
import { useGetProfile } from '../profile/components/hooks/useGetProfile'
import type { FollowersModalType } from '../profile/types'
import './dashboardCustomStyles.css'

export const Dashboard = () => {
  /** @todo When we remove profile queries entirely, */
  /** consider combining this 'profile' object with userProfile that comes from dashboardData */
  const { profile } = useGetProfile()
  const { isAnonymous } = useFirebaseUser()
  const isDashboardV3FeedEnabled = useFlag('dashboardV3Feed')
  const isMobileBottomNavEnabled = useFlag('mobileBottomNav')
  const isTripsHideEnabled = useFlag('tripsHide')
  const { dashboardData, isLoading } = useGetDashboardQuery()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false)
  const [isModalAvatarOpen, setIsModalAvatarOpen] = useState(false)
  const [isModalBannerOpen, setIsModalBannerOpen] = useState(false)
  const [scrollId, setScrollId] = useState('')
  const [showModalFollowers, setShowModalFollowers] =
    useState<FollowersModalType>(null)
  const {
    experienceBookings,
    hotelBookings,
    collections,
    trips,
    userGuideDrafts,
    userProfile,
  } = dashboardData?.currentUser ?? {}
  /** @todo see if we can get a 'bookingsCount' on the BE instead of adding the two types together on the FE */
  const bookingsCount =
    experienceBookings?.totalCount + hotelBookings?.totalCount
  const { guideCount } = userProfile ?? {}
  const tab = searchParams.get(DashboardSearchParam.tab) ?? DashboardTab.guides

  const onModalAvatarOpen = () => {
    setIsModalAvatarOpen(true)
    setIsModalInfoOpen(false)
  }

  const onModalAvatarDismiss = () => {
    setIsModalAvatarOpen(false)
    setIsModalInfoOpen(true)
  }

  const onModalBannerOpen = () => {
    setIsModalInfoOpen(false)
    setIsModalBannerOpen(true)
  }

  const onModalBannerDismiss = () => {
    setIsModalBannerOpen(false)
    setIsModalInfoOpen(true)
  }

  const onModalInfoOpen = () => setIsModalInfoOpen(true)

  const onScrollIdChange = (updatedScrollId: string) =>
    setScrollId(updatedScrollId)

  const toggleFollowersModal = (viewType: FollowersModalType) =>
    setShowModalFollowers(viewType)

  useEffect(() => {
    if (isAnonymous)
      navigate('/', {
        replace: true,
      })
  }, [isAnonymous])

  if (isLoading)
    return (
      <DashboardLoading
        isDashboardV3FeedEnabled={isDashboardV3FeedEnabled}
        isMobileBottomNavEnabled={isMobileBottomNavEnabled}
        isTripsHideEnabled={isTripsHideEnabled}
        tab={tab}
      />
    )

  return (
    <>
      <section className='md:bg-warm-grey pb-8'>
        <DashboardHeader
          currentUser={dashboardData?.currentUser}
          toggleFollowersModal={toggleFollowersModal}
          onModalInfoOpen={onModalInfoOpen}
          onScrollIdChange={onScrollIdChange}
        />
        {/** @todo created a one-off page layout container for now */}
        <div className='max-w-452 mx-auto my-0 w-full md:px-6 lg:px-12'>
          <div className='lg:mt--43 relative flex flex-col lg:min-h-[calc(100vh-78px)] lg:flex-row lg:gap-4 lg:px-0'>
            <aside className='min-w-84 w-84 rounded-3 hidden space-y-10 lg:block'>
              <DashboardProfile
                dashboardData={dashboardData}
                toggleFollowersModal={toggleFollowersModal}
                onModalInfoOpen={onModalInfoOpen}
                onScrollIdChange={onScrollIdChange}
              />
              <DashboardAchievements numberOfCreatedGuides={guideCount} />
              <DashboardAccountSettings
                userId={dashboardData?.currentUser?.id}
              />
            </aside>
            <section className='dashboard-center-column shadow-3 rounded-3 flex min-w-0 grow flex-col px-3.5 py-6 md:bg-white'>
              <DashboardNav
                bookingsCount={bookingsCount}
                collectionsCount={collections?.totalCount ?? 0}
                guideDraftsCount={userGuideDrafts?.totalCount ?? 0}
                tripsCount={trips?.totalCount ?? 0}
              />
              <div className='md:bg-warm-grey md:border-1px md:border-grey-200 md:border-t-none rounded-b-3 grow border-none px-6 py-12 md:border-solid md:bg-opacity-50'>
                {tab === DashboardTab.feed &&
                  (isDashboardV3FeedEnabled ? (
                    <DashboardFeed
                      toggleFollowersModal={toggleFollowersModal}
                    />
                  ) : (
                    <DashboardFeedOld />
                  ))}
                {tab === DashboardTab.guides && <DashboardGuideDrafts />}
                {tab === DashboardTab.trips && !isTripsHideEnabled && (
                  <DashboardTrips />
                )}
                {tab === DashboardTab.bookings && <DashboardBookings />}
                {tab === DashboardTab.collections && <DashboardCollections />}
              </div>
            </section>
            <aside className='min-w-89 w-89 shadow-3 rounded-3 dashboard-right-column hidden h-fit space-y-6 bg-white'>
              <DashboardCtas
                dashboardData={dashboardData}
                toggleFollowersModal={toggleFollowersModal}
              />
            </aside>
          </div>
        </div>
      </section>
      {isMobileBottomNavEnabled && <MobileBottomNav />}
      {isModalAvatarOpen && (
        <DashboardProfileAvatarModal
          profile={profile}
          onDismiss={onModalAvatarDismiss}
        />
      )}
      {isModalBannerOpen && (
        <DashboardProfileBannerModal
          profile={profile}
          onDismiss={onModalBannerDismiss}
        />
      )}
      {isModalInfoOpen && (
        <DashboardProfileInfoModal
          profile={profile}
          scrollId={scrollId}
          onDismiss={() => setIsModalInfoOpen(false)}
          onModalAvatarOpen={onModalAvatarOpen}
          onModalBannerOpen={onModalBannerOpen}
        />
      )}
      {showModalFollowers && (
        <FollowersModal
          accountHandle={profile?.accountHandle}
          toggleModal={toggleFollowersModal}
          userId={profile?.userId}
          viewType={showModalFollowers}
          onClose={() => setShowModalFollowers(null)}
        />
      )}
    </>
  )
}
