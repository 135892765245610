import { useState } from 'react'
import type { ButtonVariant, IconButtonProps } from '@travelpass/design-system'
import { Button, useSnackbar } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { SignInModal } from 'src/pages/signin'
import { useIncrementGuideEventLikeCountMutation } from '../useIncrementGuideEventLikeCountMutation'

interface GuideEventMetricLikeProps {
  guideEventData: Pick<
    PublishedEvent,
    'id' | 'likedByCurrentUser' | 'publishedEventLikedByUsers'
  >
  hideLabel?: boolean
  isDisabled?: boolean
  size?: IconButtonProps['size']
  variant?: ButtonVariant
}

export const GuideEventMetricLike = ({
  guideEventData,
  hideLabel = false,
  isDisabled = false,
  size = 'small',
  variant = 'text',
}: GuideEventMetricLikeProps) => {
  const { isAnonymous } = useFirebaseUser()
  const isGuideEventLikesEnabled = useFlag('guideEventLike')
  const [incrementGuideEventLikeCount] =
    useIncrementGuideEventLikeCountMutation()
  const { addErrorSnack } = useSnackbar()
  const [showSignModal, setShowSignModal] = useState(false)
  const { id, likedByCurrentUser, publishedEventLikedByUsers } =
    guideEventData ?? {}
  const { totalCount } = publishedEventLikedByUsers ?? {}

  const onLikeGuideEvent = async () => {
    if (isAnonymous) {
      setShowSignModal(true)
    } else {
      try {
        incrementGuideEventLikeCount({
          variables: {
            publishedEventId: id,
          },
        })
      } catch (errror) {
        addErrorSnack({ timeout: 1000, title: 'Already liked!' })
      }
    }
  }

  if (!isGuideEventLikesEnabled) return

  return (
    <>
      <Button
        aria-label='Like this event'
        aria-pressed={likedByCurrentUser}
        isDisabled={isDisabled}
        size={size}
        startIcon={likedByCurrentUser ? 'favorite' : 'favoriteBlank'}
        variant={variant}
        onClick={debounce(onLikeGuideEvent, 100)}
      >
        {!hideLabel && totalCount?.toString()}
      </Button>
      {showSignModal && <SignInModal onClose={() => setShowSignModal(false)} />}
    </>
  )
}
