import { useRef, type ContextType } from 'react'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Scroller, TravelpassLogo } from 'src/common/components'
import { DashboardFeedSuggestedGuideCard } from './DashboardFeedSuggestedGuideCard'
import { useGetDashboardFeedSuggestedTravelpassGuidesQuery } from '../../useGetDashboardFeedSuggestedTravelpassGuidesQuery'

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

export const DashboardFeedSuggestedTravelpassGuides = () => {
  const { dashboardFeedSuggestedTravelpassGuideData, hasError, isLoading } =
    useGetDashboardFeedSuggestedTravelpassGuidesQuery()
  const apiRef = useRef({} as ScrollVisibilityApiType)
  if (
    isLoading ||
    hasError ||
    !dashboardFeedSuggestedTravelpassGuideData?.length
  )
    return

  return (
    <section className='of-hidden rounded-2 b-1 b-solid b-grey-200 relative h-full space-y-3 bg-white p-4 md:p-6'>
      <header className='flex flex-row gap-2'>
        <div className='grow space-y-1.5'>
          <h4 className='type-h6 font-700 c-forest-dark flex flex-row items-center justify-center gap-2'>
            <TravelpassLogo className='w-4.5' variant='symbol-valley' />
            <span className='line-clamp-2 grow'>Travelpass Guides</span>
          </h4>
          <p className='type-small-text-desktop type-body-2-mobile c-grey-900 line-clamp-2'>
            Get the inside scoop on top destinations from the Travelpass team.
          </p>
        </div>
      </header>
      <div className='m-r--4 lg:m-r--6'>
        <Scroller
          allowTouch={true}
          apiRef={apiRef}
          hideScrollBar={true}
          scrollContainerClassName='gap-1 p-r-4 lg:p-r-6'
        >
          {dashboardFeedSuggestedTravelpassGuideData.map(guide => (
            <DashboardFeedSuggestedGuideCard
              key={guide?.node?.id}
              dashboardFeedSuggestedGuideItem={guide}
              isTravelpass={true}
            />
          ))}
        </Scroller>
      </div>
    </section>
  )
}
