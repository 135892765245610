import { Button, Illustration } from '@travelpass/design-system'
import type { UserProfile, Participant } from 'src/__generated__/graphql'
import type { ToggleVoteModalProps } from './dispatchToggleVoteModal'
interface ContestantCardProps {
  canVoteLeaderboard: Participant['canVoteLeaderboard']
  handleProfileVideoClick: VoidFunction
  onVoteClick: VoidFunction
  voteCount: Participant['voteCount']
  userProfile: ToggleVoteModalProps &
    Pick<UserProfile, 'id' | 'introVideoEmbed'>
}

export const ContestantCard = ({
  canVoteLeaderboard,
  handleProfileVideoClick,
  onVoteClick,
  voteCount,
  userProfile,
}: ContestantCardProps) => {
  const { accountHandle, displayName, profileImageUrl } = userProfile ?? {}

  return (
    <div className='b-none b-rounded-md max-w-70.5 mx-auto h-full w-full bg-white'>
      <button
        aria-label={`Click to view ${displayName}'s video`}
        className='m-none b-none p-none of-hidden pointer-events-none relative w-full rounded-t-md bg-white'
        onClick={handleProfileVideoClick}
      >
        {/* SVG code copied from Figma export: https://www.figma.com/design/bIooRSMT5iHNiMkLkNdFA0/TPG-2023-Travelpass.com-2.0--%3E-WIP?node-id=29233-189996&t=hmb2juKfHPUbyeqB-4 */}
        <i className='right-50% bottom-50% absolute h-4 w-4 min-w-4'>
          <svg fill='none' height='34' viewBox='0 0 29 34' width='29'>
            <path
              d='M29 17.0633L0.499998 33.2919L0.5 0.834784L29 17.0633Z'
              fill='white'
            />
          </svg>
        </i>
        {profileImageUrl ? (
          <img
            alt={`${displayName ?? "Contestant's"} profile`}
            className='pointer-events-auto m-auto block aspect-square w-full cursor-pointer object-cover'
            src={profileImageUrl}
          />
        ) : (
          <div className='aspect-square'>
            <Illustration name='publicProfile' />
          </div>
        )}
      </button>
      <section className='space-y-5 p-4'>
        <div>
          <a
            className='decoration-none type-h5 transition-opacity-200 block w-fit truncate hover:opacity-50 active:opacity-50'
            href={`/profile/${accountHandle}`}
            rel='noreferrer'
            target='_blank'
          >
            {displayName}
          </a>
          <a
            className='decoration-none c-forestLight type-body-1 transition-opacity-200 block w-fit truncate hover:opacity-50 active:opacity-50'
            href={`/profile/${accountHandle}`}
            rel='noreferrer'
            target='_blank'
          >
            @{accountHandle}
          </a>
        </div>
        <div className='flex items-center justify-between'>
          <Button
            isDisabled={!canVoteLeaderboard}
            size='small'
            startIcon='thumbUpOffAlt'
            onClick={onVoteClick}
          >
            {canVoteLeaderboard ? 'Vote' : 'Voted'}
          </Button>
          <p className='type-button truncate break-words'>{voteCount} Votes</p>
        </div>
      </section>
    </div>
  )
}
