import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
} from '@travelpass/design-system'
import { FormProvider } from 'react-hook-form'
import { DashboardAccountSettingsFormInfo } from './DashboardAccountSettingsFormInfo'
import { DashboardAccountSettingsFormNav } from './DashboardAccountSettingsFormNav'
import { DashboardAccountSettingsFormNotifications } from './DashboardAccountSettingsFormNotifications'
import { DashboardAccountSettingsFormSecurity } from './DashboardAccountSettingsFormSecurity'
import { useDashboardAccountSettingsForm } from '../../..//useDashboardAccountSettingsForm'
import { useGetDashboardAccountSettingsFormQuery } from '../../../useGetDashboardAccountSettingsFormQuery'

interface DashboardAccountSettingsFormProps {
  onDismiss: () => void
  userId: string
  settingsTab: string
}

export const DashboardAccountSettingsForm = ({
  onDismiss,
  userId,
  settingsTab,
}: DashboardAccountSettingsFormProps) => {
  const { dashboardAccountSettingsFormData, hasError, isLoading } =
    useGetDashboardAccountSettingsFormQuery(userId)
  const { activeIndex, methods, onActiveIndexChange, onSubmit } =
    useDashboardAccountSettingsForm({
      dashboardAccountSettingsFormData,
      onDismiss,
      settingsTab,
    })

  if (hasError || isLoading) return

  return (
    <FormProvider {...methods}>
      <Modal size='large' title='Account Settings' onDismiss={onDismiss}>
        <ModalScrollContents>
          <div className='p-x-4 md:p-x-0 p-b-4 space-y-9'>
            <DashboardAccountSettingsFormNav
              activeIndex={activeIndex}
              onActiveIndexChange={onActiveIndexChange}
            />
            {activeIndex === 0 && <DashboardAccountSettingsFormInfo />}
            {activeIndex === 1 && <DashboardAccountSettingsFormSecurity />}
            {activeIndex === 2 && <DashboardAccountSettingsFormNotifications />}
          </div>
        </ModalScrollContents>
        <ModalActions>
          <Button autoFocus={true} onClick={methods.handleSubmit(onSubmit)}>
            Save Changes
          </Button>
          <Button variant='outlined' onClick={onDismiss}>
            Cancel
          </Button>
        </ModalActions>
      </Modal>
    </FormProvider>
  )
}
